import { formatDateToString } from '@Util/stringUtils'
// import {OrderPageBar} from "@Component/common/Index/Index";
import { getModalInstance } from '@Component/common/Modal/ModalProvider'
import { DeliveryTracker } from '@Component/common/DeliveryTracker/DeliveryTracker'
import OrderView from '@Component/domains/order/OrderView'
import { carrierNameMapping, OrderDto, paymentType } from '@Types/order'
import React from 'react'
import styles from '../index.module.scss'
import Button from '@Component/common/Input/Button'
import ImageTag from '@Component/common/Image/ImageTag'
import { formatDate } from '@Util/dateUtile'
import { getLabelByValue } from '@Util/codeUtil'

interface OrderFlexItemProps {
    order?: OrderDto.Response
}

export const OrderFlexItem = (props: OrderFlexItemProps) => {
    const { order } = props

    const modal = getModalInstance()

    return (
        <div className={styles.orderItem}>
            <div className={styles.header}>
                <p>{order?.orderState}</p>
                <p>
                    <span> {order?.orderCode}</span>
                    <Button
                        name={'상세조회'}
                        onClick={() => {
                            modal?.popup(
                                <OrderView
                                    orderCode={order?.orderCode || ''}
                                />,
                                '900px'
                            )
                        }}
                    />
                </p>
            </div>
            <div className={styles.body}>
                <div className={styles.thumbnail}>
                    <ImageTag
                        src={`${process.env.ORDER_PRODUCT_IMAGE_BASE_URL}${
                            order?.orderItemList
                                ? order?.orderItemList[0]?.product?.productId
                                : ''
                        }/THUMBNAIL/0.png`}
                        alt={`${order?.orderName} thumbnail`}
                    />
                </div>
                <div className={styles.info}>
                    <p>{order?.orderName}</p>
                    <p>
                        결제금액 : {order?.paymentAmount?.toLocaleString()} 원
                    </p>
                    {order?.deliveryCode && (
                        <a
                            onClick={() => {
                                modal?.popup(
                                    <DeliveryTracker
                                        deliveryCode={order?.deliveryCode!}
                                        deliveryNumber={order?.deliveryNumber!}
                                    />
                                )
                            }}
                        >
                            배송번호 :{' '}
                            {`${carrierNameMapping[order?.deliveryCode]}
                                     ${order?.deliveryNumber}`}
                        </a>
                    )}
                    <div>
                        <p>
                            <span>
                                {formatDateToString(
                                    order?.orderDate
                                        ? new Date(order?.orderDate)
                                        : null
                                )}
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

interface OrderTableItemProps {
    order: OrderDto.Response
}

export const OrderTableItem = (props: OrderTableItemProps) => {
    const { order } = props
    const openModal = () => {
        if (order.orderCode) {
            const modal = getModalInstance()
            modal?.popup(
                <OrderView orderCode={order?.orderCode} isAdmin={true} />
            )
        }
    }
    return (
        <React.Fragment>
            <tr onClick={openModal}>
                <td colSpan={8}>{order?.orderName}</td>
            </tr>
            <tr onClick={openModal}>
                <td colSpan={2}>{order?.orderState}</td>
                <td colSpan={2}>
                    {getLabelByValue(paymentType, order?.paymentType)}
                </td>
                <td colSpan={2}>{order?.user?.userName}</td>
                <td colSpan={2}>
                    {formatDate(order?.orderDate, 'yyyy/MM/dd')}
                </td>
            </tr>
            <tr onClick={openModal}>
                <td colSpan={3}>{order?.orderCode}</td>
                <td colSpan={3}>
                    {order?.deliveryCode
                        ? `${carrierNameMapping[order?.deliveryCode]} ${
                              order?.deliveryNumber
                          }`
                        : '-'}
                </td>
                <td colSpan={2}>{order?.paymentAmount?.toLocaleString()} 원</td>
            </tr>
        </React.Fragment>
    )
}
