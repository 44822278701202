// productFormList.ts

import {FormFieldProps} from 'components/layout/FormField';
import {setNumber} from "@Util/formUtils";


export const formList: FormFieldProps[] = [
    {
        label: '상품 기본 정보',
        inputType: 'subTitle'
    },
    {
        label: '상품 코드',
        placeholder: 'PRODUCT CODE',
        inputType: 'text',
        name: 'productCode'

    },
    {
        label: '상품 이름',
        placeholder: 'PRODUCT NAME',
        inputType: 'text',
        name: 'name'
    },
    {
        label: '상품 가격',
        placeholder: 'PRODUCT PRICE',
        inputType: 'text',
        name: 'price',
        onChange: event => setNumber(event)
    },
    {
        label: '배송비',
        placeholder: 'PRODUCT DELIVERY FEE',
        inputType: 'text',
        name: 'deliveryFee',
        onChange: event =>  setNumber(event)
    },
    {
        label: '배송비무료 기준',
        placeholder: 'DELIVERY FEE THRESHOLD',
        inputType: 'text',
        name: 'deliveryFeeThreshold',
        onChange: event =>  setNumber(event)
    },
];
