// DefaultSettings.ts

// Default setting for BoardDto.Save
import {BoardDto} from "@Types/board/boardDto";
import {subMonths} from "date-fns";

export const createBoardSaveDto = (categoryId:number, productId?:string): BoardDto.Save => ({
    boardId: '',
    category: categoryId,
    title: '',
    content: '',
    reviewRating: '0',
    productId: productId,
    isHidden: false
});

// Default setting for BoardDto.Search
export const createBoardSearchDto = (category: number): BoardDto.Search => {

    const endDate = new Date()
    endDate.setHours(23, 59, 59, 999)

    return {
        category: category,
        productId: '',
        answer: false,
        delete: false,
        content: '',
        endDate: endDate,
        title: '',
        startDate: subMonths(new Date(), 3),  // Subtract 3 months from the current date
    };
};

