import { saveProductApi, } from '@Api/admin/product'
import { ProductDto } from "@Types/product/productDto";
import { getModalInstance } from "@Component/common/Modal/ModalProvider";

import { getProductApi } from "@Api/user/product";
import { setFileList } from '@Util/imageUtils';


export const saveProduct = async (
    saveDto: ProductDto.Save,
    fileMap: Map<number, File>,
    freeView?: boolean
) => {
    const modal = getModalInstance();
    if (fileMap.size == 0) {
        modal?.alert(() => {
        }, '확인', '메인 이미지를 등록해주세요.')
        return
    }

    const formData = new FormData();
    setFileList(formData, fileMap)

    saveDto.price = saveDto.price?.replaceAll(',', '')
    saveDto.deliveryFee = saveDto.deliveryFee?.replaceAll(',', '')
    saveDto.deliveryFeeThreshold = saveDto.deliveryFeeThreshold?.replaceAll(',', '')
    formData.append('saveDto', JSON.stringify(saveDto))

    await saveProductApi(formData, freeView)
}

export const freeView = async (saveDto: ProductDto.Save,
    fileMap: Map<number, File>,) => {

    const tempDto = saveDto;
    tempDto.productId = '1'
    tempDto.productCode = 'SAMPLE'

    await saveProduct(tempDto, fileMap, true)

    window.open(
        '/admin/product/freeView/1',
        '_blank',
        'width=1000,height=800'
    )
}

export const setViewedProduct = (productId?: string) => {
    if (!productId) {
        return
    }
    const viewProductList = document.cookie
        .split('; ')
        .find((row) => row.startsWith('viewProductList='))
        ?.split('=')[1]
        ?.split(',')

    if (viewProductList) {
        const newViewProductList = [productId]
        viewProductList.forEach((viewProduct) => {
            if (newViewProductList.length < 10 && viewProduct !== productId) {
                newViewProductList.push(viewProduct)
            }
        })
        const expiresDate = new Date()
        expiresDate.setDate(expiresDate.getDate() + 365)
        document.cookie = `viewProductList=${newViewProductList.join(
            ','
        )};expires=${expiresDate.toUTCString()};path=/`
    } else {
        const expiresDate = new Date()
        expiresDate.setDate(expiresDate.getDate() + 365)
        document.cookie = `viewProductList=${productId};expires=${expiresDate.toUTCString()};path=/`
    }
}

export const getViewedProduct = async () => {
    const cookieValue = document.cookie
        .split('; ')
        .find((row) => row.startsWith('viewProductList='))
        ?.split('=')[1]

    if (cookieValue) {
        const tempList: ProductDto.Response[] = []
        const productIdList = cookieValue.split(',')
        for (const productId of productIdList) {
            const product = await getProductApi(productId, false)
            if (product) {
                tempList.push(product)
            }
        }
        return tempList

    } else {
        return []
    }
}
