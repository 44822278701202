import MainFrame, {ButtonOption} from '@Component/layout/MainFrame'
import {CmmDto} from '@Types/cmm'
import React, {useEffect, useState} from 'react'
import Header, {HeaderOption} from '@Component/layout/ListField/Header'
import ListTemplate, {RowOption,} from '@Component/layout/ListField/ListTemplate'
import Item from '@Component/layout/ListField/Item'
import {createDefaultAnalytics} from '@Types/cmm/default'
import {formatDate} from '@Util/dateUtile'
import styles from './index.module.scss'

export const Analytics: React.FC<{ summery?: CmmDto.Summery }> = ({
                                                                      summery,
                                                                  }) => {
    const [analyticsType, setAnalyticsType] = useState<string>('day')
    const [analytics, setAnalytics] = useState<CmmDto.Analytics[]>()

    useEffect(() => {
        let analytics =
            (analyticsType == 'day'
                ? summery?.dailyAnalytics
                : summery?.monthAnalytics) || []
        const targetDays = analyticsType == 'day' ? 7 : 6
        const currentLength = analytics.length
        if (currentLength < targetDays) {
            const missingDays = targetDays - currentLength
            const lastDate = analytics[currentLength - 1]?.analyticsDate
            let nextDate = lastDate ? new Date(lastDate) : new Date()
            const fillerData = Array.from(
                {length: missingDays},
                (_, index) => {
                    if (analyticsType == 'day') {
                        nextDate.setDate(nextDate.getDate() - 1)
                    } else {
                        nextDate.setMonth(nextDate.getMonth() - 1)
                    }
                    const nextDateString = nextDate.toISOString().split('T')[0]
                    return createDefaultAnalytics(nextDateString) // 계산된 날짜로 기본 데이터 생성
                }
            )
            analytics = [...analytics, ...fillerData]
        }
        setAnalytics(analytics)
    }, [summery, analyticsType])
    const action: ButtonOption[] = [
        {
            name: analyticsType == 'day' ? '월간통계보기' : '일간통계보기',
            onClick: () => {
                if (analyticsType == 'day') {
                    setAnalyticsType('mon')
                } else {
                    setAnalyticsType('day')
                }
            },
        },
    ]

    const headerOptions: HeaderOption[] = [
        {
            name: analyticsType == 'day' ? '일자' : '월',
            rowCount: 1,
            width: 'auto',
        },
        {name: '방문자', rowCount: 1, width: '10%'},
        {name: '조회', rowCount: 1, width: '10%'},
        {name: '주문', rowCount: 1, width: '10%'},
        {name: '매출액', rowCount: 1, width: '25%'},
        {name: '문의', rowCount: 1, width: '10%', hidden: true},
        {name: '후기', rowCount: 1, width: '10%', hidden: true},
        {name: '가입', rowCount: 1, width: '10%'},
    ]

    const rowOptions: RowOption[] =
        analytics?.map((item, index) => ({
            rowList: [
                <Item
                    content={formatDate(
                        item.analyticsDate,
                        analyticsType == 'day' ? 'yy.MM.dd' : 'yy.MM'
                    )}
                    colSpan={1}
                />,
                <Item content={item.visitorCount + ' 건'} colSpan={1}/>,
                <Item content={item.pageViewCount + ' 회'} colSpan={1}/>,
                <Item content={item.orderCount + ' 건'} colSpan={1}/>,
                <Item
                    content={item.revenue?.toLocaleString() + ' 원'}
                    colSpan={1}
                />,
                <Item
                    content={item.inquiryCount + ' 건'}
                    colSpan={1}
                    hidden={true}
                />,
                <Item
                    content={item.reviewCount + ' 건'}
                    colSpan={1}
                    hidden={true}
                />,
                <Item content={item.userCount + ' 명'} colSpan={1}/>,
            ],
        })) || []

    const aggregateRow = {
        rowList: [
            <Item
                content={analyticsType == 'day' ? '주간통계' : '월간통계'}
                colSpan={1}
            />,
            <Item
                content={
                    analytics?.reduce(
                        (sum, item) => sum + (item?.visitorCount ?? 0),
                        0
                    ) + ' 건'
                }
                colSpan={1}
            />,
            <Item
                content={
                    analytics?.reduce(
                        (sum, item) => sum + (item?.pageViewCount ?? 0),
                        0
                    ) + ' 회'
                }
                colSpan={1}
            />,
            <Item
                content={
                    analytics?.reduce(
                        (sum, item) => sum + (item?.orderCount ?? 0),
                        0
                    ) + ' 건'
                }
                colSpan={1}
            />,
            <Item
                content={
                    analytics?.reduce(
                        (sum, item) => sum + (item?.revenue ?? 0),
                        0
                    ) + ' 원'
                }
                colSpan={1}
            />,
            <Item
                content={
                    analytics?.reduce(
                        (sum, item) => sum + (item?.inquiryCount ?? 0),
                        0
                    ) + ' 건'
                }
                colSpan={1}
                hidden={true}
            />,
            <Item
                content={
                    analytics?.reduce(
                        (sum, item) => sum + (item?.reviewCount ?? 0),
                        0
                    ) + ' 건'
                }
                colSpan={1}
                hidden={true}
            />,
            <Item
                content={
                    analytics?.reduce(
                        (sum, item) => sum + (item?.userCount ?? 0),
                        0
                    ) + ' 명'
                }
                colSpan={1}
            />,
        ],
    }

    rowOptions.push(aggregateRow)

    return (
        <MainFrame
            title={analyticsType == 'day' ? '일간 통계' : '월간 통계'}
            marginZero={true}
            action={action}
            className={styles.analytics}
        >
            <ListTemplate
                header={<Header options={headerOptions}/>}
                row={rowOptions}
            />
        </MainFrame>
    )
}