import { PaymentResponse } from '@portone/browser-sdk/dist/v2/requestPayment'
import { getModalInstance } from '@Component/common/Modal/ModalProvider'

import { HttpAlert, HttpSuccess } from '@Types/cmm/HttpCallBack'
import { getRequest, postRequest, putRequest } from '@Api/Api'
import { OrderDto } from '@Types/order'
import { formatDate } from '@Util/dateUtile'
import {setFileList} from "@Util/imageUtils";


export const insertOrderApi = async (orderList: OrderDto.Item[]) => {
    try {
        await postRequest('/order', orderList)
        return true
    } catch (e) {
        console.log(e)
        return false
    }
}

export const getOrderApi = async (orderCode?: string) => {
    const modal = getModalInstance()
    try {
        const httpSuccess: HttpSuccess = {}
        const httpAlert: HttpAlert = {}

        return await getRequest<OrderDto.Response>(
            `/order/${orderCode || ''}`,
            httpSuccess,
            httpAlert,
            {}
        )
    } catch (e) {
        console.log(e)
    }
}

export const getOrderCountApi = async () => {
    const modal = getModalInstance()
    try {
        const httpSuccess: HttpSuccess = {}
        const httpAlert: HttpAlert = {}

        return await getRequest<any>(`/order/count`, httpSuccess, httpAlert, {})
    } catch (e) {
        console.log(e)
    }
}

export const getOrderListApi = async (searchQuery: OrderDto.Search) => {
    const modal = getModalInstance()
    try {
        searchQuery.startDate = formatDate(searchQuery?.startDate?.toString())
        searchQuery.endDate = formatDate(searchQuery?.endDate?.toString())
        const httpSuccess: HttpSuccess = {}
        const httpAlert: HttpAlert = {
            401: () => {
                modal?.alert(
                    () => {
                        window.location.href = '/user/login'
                    },
                    '알림',
                    '로그인 후 이용가능합니다.'
                )
            },
        }
        const config = {
            params: searchQuery,
        }

        return await getRequest<OrderDto.Page>(
            '/order/list',
            httpSuccess,
            httpAlert,
            config
        )
    } catch (e) {
        console.log(e)
    }
}

export const getOrderExcelListApi = async (searchQuery: OrderDto.Search) => {
    const modal = getModalInstance()
    try {
        const httpSuccess: HttpSuccess = {}
        const httpAlert: HttpAlert = {}
        const config = {
            params: searchQuery,
        }

        return await getRequest<OrderDto.Response[]>(
            '/admin/order/excel',
            httpSuccess,
            httpAlert,
            config
        )
    } catch (e) {
        console.log(e)
    }
}

export const orderWebHookService = async (data: PaymentResponse | any) => {
    const modal = getModalInstance()

    try {
        const httpSuccess: HttpSuccess = {
            200: (data) => {
                window.location.href = `/order/complete?orderCode=${data}`
            },
        }
        const httpAlert: HttpAlert = {
            400: () => {
                modal?.alert(
                    () => {
                        window.location.href = '/'
                    },
                    '경고',
                    '잘못된 요청',
                    '결제금액이 다릅니다.'
                )
            },
        }

        return await postRequest(
            '/order/web-hook',
            data,
            httpSuccess,
            httpAlert,
            {}
        )
    } catch (e) {
        console.log(e)
    }
}

export const updateOrderApi = async (
    order: OrderDto.UserUpdate,
    fileMap?: Map<number, File>
) => {
    const modal = getModalInstance()
    const formData = new FormData();
    if(fileMap){
        setFileList(formData, fileMap)
    }
    formData.append('saveDto', JSON.stringify(order))

    try {
        const httpAlert: HttpAlert = {
            403: () => {
                modal?.alert(
                    () => { },
                    '알림',
                    '요청 불가 상태입니다.',
                    '관리자에게 문의해주세요.'
                )
            },
        }

        await putRequest('/order', formData, {}, httpAlert, {
            headers: {
                'Content-Type': 'multipart/form-data', // 멀티파트 요청의 Content-Type 지정
            },
        })

        return true
    } catch (e) {
        console.log(e)
        return false
    }
}

