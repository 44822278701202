import { OrderDto } from '@Types/order'
import { useEffect, useState } from 'react'

import { getModalInstance } from '@Component/common/Modal/ModalProvider'
import styles from './index.module.scss'
import MainFrame from '@Component/layout/MainFrame'
import { getOrderCountApi } from '@Api/user/order'

const OrderDashBoard: React.FC<{ isAdmin?: boolean }> = ({ isAdmin }) => {
    const [orderCount, setOrderCount] = useState<OrderDto.Count>()
    const queryParams = new URLSearchParams(location.search)
    const code = queryParams.get('code')

    const fetchData = async () => {
        setOrderCount(await getOrderCountApi())
    }

    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        const modal = getModalInstance()
        if (code == '409') {
            modal?.alert(
                () => {},
                '알림',
                '계정이 연동되어있습니다.',
                '이미 연동된 계정입니다.'
            )
        }
    }, [code])

    return (
        <MainFrame className={styles.dashBoard}>
            <div>
                <a>
                    <p>주문완료</p>
                    <p>({orderCount?.completeOrderCount || 0})</p>
                </a>
            </div>
            <div>
                <i className="icon-right-open"></i>
            </div>
            <div>
                <a>
                    <p>준비중</p>
                    <p>({orderCount?.preparationCount || 0})</p>
                </a>
            </div>
            <div>
                <i className="icon-right-open"></i>
            </div>
            <div>
                <a>
                    <p>배송중</p>
                    <p>({orderCount?.inDeliveryCount || 0})</p>
                </a>
            </div>
            <div>
                <i className="icon-right-open"></i>
            </div>
            <div>
                <a>
                    <p>배송완료</p>
                    <p>({orderCount?.deliveredCount || 0})</p>
                </a>
            </div>
            <div>
                <p style={{ fontWeight: '1000' }}>/</p>
            </div>
            <div>
                <a>
                    <p>교환/반품</p>
                    <p id="exchange-refund">
                        ({orderCount?.exchangeOrRefundCount || 0})
                    </p>
                </a>
            </div>
        </MainFrame>
    )
}

export default OrderDashBoard
