import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
// import {OrderPageBar} from "@Component/common/Index/Index";
import { createSearchDto, OrderDto } from '@Types/order'

import MainFrame from '@Component/layout/MainFrame'

import { getOrderListApi } from '@Api/user/order'

import { OrderFlexItem } from '../OrderItem'

export const OrderFlexList = (props: { orderList: OrderDto.Response[] }) => {
    return (
        <MainFrame>
            {props.orderList?.map((order, index) => (
                <OrderFlexItem order={order} key={index} />
            ))}
            {props.orderList?.length === 0 && (
                <div className="empty-view">해당하는 주문이 없습니다.</div>
            )}
        </MainFrame>
    )
}
