import { getModalInstance } from '@Component/common/Modal/ModalProvider';
import React from 'react';
import styles from './index.module.scss'
import Button from '../Button';

type FileUploadComponentProps = {
    fileMap: Map<number, File>;
    setFileMap: React.Dispatch<React.SetStateAction<Map<number, File>>>;
    viewMode?: boolean
};

const validExtensions = ['.hwp', '.xlsx', '.xls', '.hwpx'];

const FileUpload: React.FC<FileUploadComponentProps> = ({ fileMap, setFileMap, viewMode }) => {
    const modal = getModalInstance();

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files) {

            const newFileMap = new Map(fileMap); // 현재 상태를 기반으로 새 맵을 만듬

            Array.from(files).forEach((file) => {
                // 확장자 확인
                const fileExtension = file.name.slice(file.name.lastIndexOf('.')).toLowerCase();
                if (!validExtensions.includes(fileExtension)) {
                    modal?.alert(() => { }, '알림', `파일 형식이 올바르지 않습니다`, '허용가능 형식 : ' + validExtensions.join(', '));
                    return;
                }

                // 같은 이름과 확장자 체크
                const isDuplicate = Array.from(newFileMap.values()).some(existingFile =>
                    existingFile.name === file.name && existingFile.size === file.size
                );
                if (isDuplicate) {
                    modal?.alert(() => { }, '알림', `같은 파일이 이미 업로드 되어 있습니다.`, `파일명: ${file.name}`);
                    return;
                }

                // 파일 추가
                newFileMap.set(newFileMap.size, file);
            });

            setFileMap(newFileMap); // 상태 업데이트
        }
    };

    const handleRemoveFile = (fileIndex: number) => {
        const newFileMap = new Map(fileMap);
        newFileMap.delete(fileIndex);
        setFileMap(newFileMap);
    };
    const handleDownloadFile = (file: File) => {

        const url = URL.createObjectURL(file);

        const link = document.createElement('a');
        link.href = url;
        link.download = file.name;

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);

        URL.revokeObjectURL(url);
    };

    return (

        <div>
            {!viewMode && <input type="file" multiple onChange={handleFileChange} />}
            <ul className={styles.fileList}>
                {[...fileMap.values()].map((file, index) => (
                    <li key={index}>
                        <p>{file.name} ({file.size} bytes)</p>
                        {viewMode ? (
                            <Button name="다운로드" onClick={() => handleDownloadFile(file)} />
                        ) : (
                            <Button name="삭제" onClick={() => handleRemoveFile(index)} />
                        )}
                    </li>
                ))}
            </ul>
        </div>

    );
};

export default FileUpload;
