// ViewChangeButton.tsx
import React from 'react';
import {useListView} from "../../../../context/board/ListViewContext";
import styles from './index.module.scss'

const ViewChangeButton: React.FC = () => {
    const {viewMode, setViewMode} = useListView();

    return (
        <React.Fragment>
            <i className={"icon-th-list " + (viewMode == 'list' ? styles.active : styles.normal)} onClick={() => {
                setViewMode('list')
            }}/>
            <i className={"icon-th-large " + (viewMode == 'grid' ? styles.active : styles.normal)} onClick={() => {
                setViewMode('grid')
            }}/>
        </React.Fragment>
    );
};

export default ViewChangeButton;
