import React, { useEffect, useState } from "react";
import ImageDrop from "@Component/common/Image/ImageDrop/ImageDrop";
import { Editor } from "@Component/common/Input/Editor/Editor";

import MainFrame, { ButtonOption } from "@Component/layout/MainFrame";
import styles from "@Css/form.module.scss";
import FormField, { FormFieldProps } from "@Component/layout/FormField";

import { reviewValid } from "@Component/domains/board/Edit/valid";
import { BoardCategory, BoardDto, createBoardSaveDto, mapResponseToSave } from "@Types/board";
import ReviewRating from "@Component/domains/board/ReviewRating";
import { getBoardApi } from "@Api/user/board";
import { isValid } from "@Util/formUtils";
import { saveBoard } from "@Service/board";
import { getFileMap } from "@Util/imageUtils";

export const ReviewEditor = (props: {
    productId?: string
    boardId?: string
}) => {
    const [fileMap, setFileMap] = useState<Map<number, File>>(new Map())
    const [saveDto, setSaveDto] = useState<BoardDto.Save>(createBoardSaveDto(BoardCategory.Review, props.productId))
    const [defaultData, setDefaultData] = useState<string>()
    const reviewForm: FormFieldProps[] = [
        {
            label: '한줄평',
            rightLabel: (<div className={styles.flex}>상품평점<ReviewRating data={saveDto} setData={setSaveDto} name={'reviewRating'}
                size={'var(--font-size-1-1)'} /></div>)
            ,
            placeholder: 'REVIEW TITLE',
            inputType: 'text',
            name: 'title'
        },
    ];


    const actionButton: ButtonOption[] = [
        {
            name: props.boardId ? '수정' : '저장',
            onClick: async () => {
                if (await isValid(saveDto, reviewValid, true)) {
                    saveBoard(saveDto, fileMap)
                }
            }
        }
    ]
    const fetchData = async (boardId?: string) => {
        if (props.boardId) {
            const response = await getBoardApi(props.boardId)
            if (response) {
                setSaveDto(mapResponseToSave(response))
                setFileMap(await getFileMap(response?.imageUrl || []));
                setDefaultData(response.content)
            }
        }
    }

    useEffect(() => {
        fetchData(props.boardId)
    }, [props.boardId])

    return (
        <MainFrame className={styles.form} maxWidth={900} title={props.boardId ? '리뷰 수정' : '리뷰 작성'} marginZero={true} width={'96%'}
            action={actionButton}>
            <FormField formFieldList={reviewForm} data={saveDto} setData={setSaveDto} validOption={reviewValid} />
            <h3>사진 첨부</h3>
            <ImageDrop
                fileMap={fileMap}
                setFileMap={setFileMap}
            />
            <h3>구매후기</h3>
            <Editor name={'content'}
                data={saveDto}
                setData={setSaveDto}
                userSet={true}
                height={300}
                defaultData={defaultData}
            />
        </MainFrame>
    )
}
