// src/types/addressDto.ts

// 주소정보 DTO 모음
import {BoardDto} from "@Types/board";
import {decodeHTML} from "@Util/stringUtils";

export namespace AddressDto {
    // 주소정보 저장 DTO
    export interface Save {
        // 주소 ID
        addressId?: string;
        // 전체 주소
        address?: string;

        addressDetail?:string;

        mainAddress?: boolean;
        // 수령인 이름
        recipientName?: string;
        // 수령인 연락처
        recipientContact?: string;
        // 주소 별칭 (예?: 집, 회사)
        addressNickName?: string;
        // 배송 요청사항
        deliveryRequest?: string;

        deliveryRequestDetail?: string;
    }

    // 주소정보 반환 DTO
    export interface Response {
        // 주소 Id
        addressId?: string;
        // 전체 주소
        address?: string;

        addressDetail?:string;

        // 대표 주소 여부 (Y/N)
        mainAddress?: boolean;
        // 수령인 이름
        recipientName?: string;
        // 수령인 연락처
        recipientContact?: string;
        // 주소 별칭 (예?: 집, 회사)
        addressNickName?: string;
        // 배송 요청사항
        deliveryRequest?: string;
    }

    export interface Search {

        keyword?: string;

        page?: number;
    }

}

export const mapResponseToSave = (response: AddressDto.Response): AddressDto.Save => {
    return {
        addressId:response.addressId,
        // 전체 주소
        address:   decodeHTML(response.address || ''),

        addressDetail:response.addressDetail,

        mainAddress: response.mainAddress,
        // 수령인 이름
        recipientName: response.recipientName,
        // 수령인 연락처
        recipientContact: response.recipientContact,
        // 주소 별칭 (예?: 집, 회사)
        addressNickName: response.addressNickName,
        // 배송 요청사항
        deliveryRequest: response.deliveryRequest,

        deliveryRequestDetail: response.deliveryRequest,
    };
};
