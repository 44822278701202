import {ValidationOption} from "@Util/formUtils";
import {ProductDto} from "@Types/product";
import {CheckDuplicate} from "@Api/admin/product";

export const saveValid: ValidationOption[] = [
    {
        name: 'orderName',
        baseMessage: '주문명을',
        disableSuccessMessage:true
    },
    {
        name: 'orderState',
        baseMessage: '주문 상태를',
        nullAbleMessage :'주문 상태를 선택해주세요.',
        disableSuccessMessage: true
    },
    {
        name: 'paymentAmount',
        baseMessage: '결제금액을',
        disableSuccessMessage:true
    },
    {
        name: 'paymentType',
        baseMessage: '결제수단을',
        nullAbleMessage: '결제수단을 선택해주세요.',
        disableSuccessMessage : true
    },
    {
        name: 'deliveryCode',
        baseMessage: '배송사를',
        nullAbleMessage: '배송사를 선택해주세요.',
        disableSuccessMessage : true
    },
    {
        name: 'deliveryNumber',
        baseMessage: '송장번호를',
        disableSuccessMessage : true
    },
    {
        name: 'address',
        baseMessage: '배송지주소를',
        disableSuccessMessage : true
    },
    {
        name: 'recipientName',
        baseMessage: '수령인 이름을',
        disableSuccessMessage : true
    },
    {
        name: 'recipientContact',
        baseMessage: '수령인 연락처를',
         disableSuccessMessage : true,
        regex: /^[0-9]{3}-[0-9]{4}-[0-9]{4}$/, // 전화번호 형식 검사 정규식
        regexMessage: '유효한 전화번호 형식이 아닙니다. (예: 010-1234-5678)',


    }, {
        name: 'deliveryRequest',
        baseMessage: '배송 요청사항을',
        disableSuccessMessage : true

    },


]
export const saveValid2: ValidationOption[] = [
    {
        name: 'accountCode',
        baseMessage: '결제은행을',
        nullAbleMessage: '결제은행을 선택해주세요.',
        disableSuccessMessage : true
    },
    {
        name: 'accountHolder',
        baseMessage: '입금자명을',
        disableSuccessMessage:true
    },
    {
        name: 'accountNumber',
        baseMessage: '계좌번호를',
        disableSuccessMessage:true
    },
];
