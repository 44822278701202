import {FormFieldProps} from "components/layout/FormField";

export const requestOptions = [
    '부재 시 경비실에 맡겨 주세요',
    '부재 시 문 앞에 두고 가세요',
    '부재 시 전화 주세요',
    '택배함에 넣어 주세요',
    '도착 전에 미리 전화 주세요',
    '직접입력',
];
