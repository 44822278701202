import React from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './DatePicker.css' // 위에서 설정한 CSS 파일 임포트
import { ko } from 'date-fns/locale' // 한국어 로케일 가져오기

const DateInput = (props: {
  selectedDate: Date
  setSelectedDate: React.Dispatch<React.SetStateAction<Date>>
  start?: boolean // boolean 타입의 start 옵션 추가
}) => {
  const handleChange = (date: Date | null) => {
    if (date) {
      const newDate = new Date(date)
      if (props.start) {
        // start가 true이면 00:00:00으로 설정
        newDate.setHours(0, 0, 0, 0)
      } else {
        // start가 false이면 23:59:59으로 설정
        newDate.setHours(23, 59, 59, 999)
      }
      props.setSelectedDate(newDate)
    }
  }

  return (
    <DatePicker
      dateFormat="yyyy.MM.dd"
      shouldCloseOnSelect
      minDate={new Date('1900-01-01')}
      maxDate={new Date()}
      selected={props.selectedDate}
      onChange={handleChange}
      locale={ko}
      popperPlacement="bottom-start" // Adjust popper placement
    />
  )
}

export default DateInput
