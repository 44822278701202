import errorImage from '@Image/view/black.png'

export const onErrorImg = (e: React.SyntheticEvent<HTMLImageElement>) => {
    e.currentTarget.src = errorImage
}

export const getFileMap = async (
    imageUrls: string[]
): Promise<Map<number, File>> => {
    try {
        // 이미지를 다운로드하여 파일 맵에 추가합니다.
        const updatedFileMap = new Map<number, File>()
        for (const imageUrl of imageUrls) {

            const response = await fetch(process.env.BASE_URL + imageUrl)
            const blob = await response.blob()
            const filename = imageUrl.substring(imageUrl.lastIndexOf('/') + 1)
            const file = new File([blob], filename, { type: blob.type })
            updatedFileMap.set(updatedFileMap.size, file)
        }

        // 파일 맵을 업데이트합니다.
        return updatedFileMap
    } catch (error) {
        console.error('Error adding images to file map:', error)
        return new Map<number, File>()
    }
}
export const setFileList = (formData: FormData, fileMap: Map<number, File>, fileName?: string) => {
    const fileList = Array.from(fileMap.values()) // 파일 맵에서 값들을 배열로 변환

    // Iterate through each file in the fileList array
    fileList.forEach((file, index) => {
        // Append each file to the FormData object with the name "files[]" and specify the MIME type
        formData.append(fileName ? fileName : `files`, file, file.name) // file.name을 통해 MIME 타입을 자동으로 추론하도록 지정할 수 있습니다.
    })


}

export const appendImageList = (formData: FormData, fileMap: Map<number, File>, fileName?: string): void => {
    const fileList = Array.from(fileMap.values()); // 파일 맵에서 값들을 배열로 변환

    fileList.forEach((file, index) => {
        formData.append(fileName ? fileName : `files`, file, file.name); // 파일을 FormData에 추가
    });
}

export const convertToJpg = async (file: File): Promise<File> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = async function (event) {
            const img = new Image()
            img.onload = async function () {
                const canvas = document.createElement('canvas')
                const ctx = canvas.getContext('2d')
                canvas.width = img.width
                canvas.height = img.height
                ctx?.drawImage(img, 0, 0, img.width, img.height)
                canvas.toBlob(
                    function (blob) {
                        if (blob) {
                            const convertedFile = new File(
                                [blob],
                                'converted.jpg',
                                { type: 'image/jpeg' }
                            )
                            resolve(convertedFile)
                        } else {
                            reject(new Error('Failed to convert image to JPG.'))
                        }
                    },
                    'image/jpeg',
                    0.85
                )
            }
            img.src = event.target?.result as string
        }
        reader.readAsDataURL(file)
    })
}

export const convertToPng = async (file: File): Promise<File> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = async function (event) {
            const img = new Image();
            img.onload = async function () {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                canvas.width = img.width;
                canvas.height = img.height;
                ctx?.drawImage(img, 0, 0, img.width, img.height);

                // Convert the canvas to a PNG blob
                canvas.toBlob(
                    function (blob) {
                        if (blob) {
                            const convertedFile = new File(
                                [blob],
                                'converted.png',
                                { type: 'image/png' }
                            );
                            resolve(convertedFile);
                        } else {
                            reject(new Error('Failed to convert image to PNG.'));
                        }
                    },
                    'image/png'
                );
            };
            img.src = event.target?.result as string;
        };
        reader.readAsDataURL(file);
    });
};

export const isJpeg = (file: File) => {
    const extension = file.name.split('.').pop()?.toLowerCase()
    return (
        extension === 'jpg' ||
        extension === 'jpeg' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/jpg'
    )
}


export const isPng = (file: File) => {
    const extension = file.name.split('.').pop()?.toLowerCase();
    return (
        extension === 'png' ||
        file.type === 'image/png'
    );
}


export const getCroppedImg = (image: File, crop: { x: number; y: number; width: number; height: number }) => {
    return new Promise<string>((resolve) => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const imageObj = new Image();

        imageObj.src = URL.createObjectURL(image);
        imageObj.onload = () => {
            canvas.width = crop.width;
            canvas.height = crop.height;
            ctx?.drawImage(
                imageObj,
                crop.x,
                crop.y,
                crop.width,
                crop.height,
                0,
                0,
                crop.width,
                crop.height
            );

            const base64Image = canvas.toDataURL('image/jpeg');
            resolve(base64Image);
        };
    });
};
