export const carrierNameMapping: Record<string, string> = {
    'kr.epost': '우체국',
    'kr.cjlogistics': 'CJ 대한통운',
    'kr.lotte': '롯데',
    'kr.hanjin': '한진',
    'kr.logen': '로젠',
    'kr.kdexp': '경동',
    'kr.cvsnet': 'GS 우편함',
}

export const bankNames = [
    'KEB 하나',
    'KB국민',
    '신한',
    '우리',
    '농협',
    '씨티',
    '스탠다드차타드',
    'HSBC',
    '기업',
    'SC제일',
    '삼성',
    'KDB산업',
    '부산',
    '대구',
    '제주',
    '신협',
    '기타',
]

export const paymentType = [
    {label: '계좌이체', value: 'ACCOUNT'},
    {label: '카드/간편결제', value: 'PAYMENT'},
]

export const RequestType = (orderState: string) => {
    let requestType = []
    if (AbleStatuses.cancelReq.includes(orderState)) {
        requestType.push({label: '취소요청', value: '취소요청'})
    }
    if (AbleStatuses.exchangeReq.includes(orderState)) {
        requestType.push({label: '교환요청', value: '교환요청'})
    }
    if (AbleStatuses.returnReq.includes(orderState)) {
        requestType.push({label: '반품요청', value: '반품요청'})
    }

    return requestType
}

export const bankOptions = bankNames.map((name) => ({
    label: name,
    value: name,
}))

export const carrierOptions = Object.entries(carrierNameMapping).map(
    ([key, value]) => ({
        label: value,
        value: key,
    })
)

interface StateOption {
    Insert: string[]
    Order: string[]
    Cancel: string[]
    Exchange: string[]
    Return: string[]
    AdminOrder: string[]
    None: string[]

    // 다른 키들 추가 가능
}
export const stateOptions = (type: keyof StateOption, orderState?: string) => {
    const baseOptions = Object.entries(StateOption[type]).map(([key, value]) => ({
        label: value,
        value: value,
    }));

    // 추가 옵션을 조건별로 처리
    const mapping: Record<string, keyof StateOption> = {
        '교환': 'Cancel',
        '반품': 'Return',
        '환불': 'Exchange',
    };

    const additionalOptions = Object.entries(mapping)
        .filter(([key]) => orderState?.includes(key)) // 조건에 해당하는 key만 필터링
        .flatMap(([, mappedType]) =>
            Object.entries(StateOption[mappedType]).map(([key, value]) => ({
                label: value,
                value: value,
            }))
        );

    return [...baseOptions, ...additionalOptions];
};

// 요청 가능 상태 목록
export namespace AbleStatuses {
    export const addressReq = ['주문대기', '입금대기', '결제완료']
    export const cancelReq = ['입금대기', '결제완료', '취소반려']
    export const returnReq = ['배송완료', '반품반려', '교환반려', '교환완료']
    export const exchangeReq = ['배송완료', '교환반려', '반품반려', '교환완료']
}

const StateOption = {
    Insert: ['배송중', '배송완료'],

    Order: ['준비중', '배송중', '배송완료'],
    AdminOrder: [
        '준비중',
        '배송중',
        '배송완료',
        '교환완료',
        '반품완료',
        '취소완료',
    ],
    Cancel: ['취소완료', '취소반려'],
    Exchange: ['교환중', '교환반려', '교환완료'],
    Return: ['반품중', '반품반려', '반품완료'],
    None: []
}
