import React, {useEffect} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'

export const ProductSortSelector = (props:{setSortMode:React.Dispatch<React.SetStateAction<string>>}) => {
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search)
        const sortModeParam = searchParams.get('sortMode')
        if (sortModeParam) {
            props.setSortMode(sortModeParam)
        } else {
            // 다른 카테고리로 이동할 때는 정렬 모드를 1로 초기화
            props.setSortMode('1')
        }
    }, [location])

    const handleSortChange = (event: { target: { value: any } }) => {
        const selectedValue = event.target.value
        props.setSortMode(selectedValue)
        const searchParams = new URLSearchParams(location.search)
        searchParams.set('sortMode', selectedValue)
        const newUrl = `${location.pathname}?${searchParams.toString()}`
        navigate(newUrl)
    }

    return (
        <select id="sort-mode" onChange={handleSortChange}>
            <option value="1">-정렬순서-</option>
            <option value="2">상품명</option>
            <option value="3">낮은가격순</option>
            <option value="4">높은가격순</option>
            <option value="5">리뷰많은순</option>
            <option value="6">평점높은순</option>
        </select>
    )
}
