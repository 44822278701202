import {UserDto} from './userDto'

export const createUserInfoDto = (): UserDto.Info => ({
    userId: '',
    email: '',
    userName: '',
    phoneNumber: '',
    userIp: '',
    role: '',
})

export const createUserJoinDto = (): UserDto.Join => ({
    loginId: '',
    password: '',
    email: '',
    userName: '',
    phoneNumber: '',
    agreement: '',
    privacy: '',
})

export const createUserLoginDto = (): UserDto.Login => ({
    loginId: '',
    password: '',
})

export const createUserUpdateDto = (
    userInfo?: UserDto.Info
): UserDto.Update => ({
    email: userInfo?.email || '',
    userName: userInfo?.userName || '',
    phoneNumber: userInfo?.phoneNumber || '',
})

export const createUserFindDto = (): UserDto.Find => ({
    email: '',
    userName: '',
    phoneNumber: '',
})

export const createUserResetDto = (): UserDto.Reset => ({
    password: '',
    loginId: '',
    email: '',
    authToken: '',
})

export const createUserDuplicationDto = (): UserDto.Duplication => ({
    loginId: '',
    email: '',
    userIp: '',
})

export const createUserTokenDto = (): UserDto.Token => ({
    userId: 0,
    accToken: '',
    refToken: '',
    validType: 'VALID',
})


export const createUserSearchDto = (): UserDto.Search => ({
    loginId: '',
    userName: '',
    phoneNumber: '',
    email:''
})
