import { useLocation, useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { UserDto } from '@Types/user'
import { checkUserInfo } from '@Service/user/UserService'

import PaymentRedirectPage from '@Page/user/cmm/Redirect'
import OrderComplete from '@Component/domains/order/OrderComplete'
import CartList from '@Component/domains/cart/CartList'
import MainFrame from '@Component/layout/MainFrame'
import styles from './index.module.scss'
import OrderCheckOut from '@Component/domains/order/OrderCheckOut'

const OrderPage = () => {
    const location = useLocation()
    const currentPath = location.pathname
    const { orderPath } = useParams<{ orderPath: string }>()

    const [userInfo, setUserInfo] = useState<UserDto.Info>()

    useEffect(() => {
        // 문서 제목 설정
        if (orderPath === 'cart') {
            document.title = 'BROSPO 장바구니'
        } else if (orderPath === 'checkout') {
            document.title = 'BROSPO 주문작성'
        } else if (orderPath === 'complete') {
            document.title = 'BROSPO 결제완료'
        }

        // 유저 정보 체크
        checkUserInfo(setUserInfo, true)
    }, [orderPath])

    // 현재 경로에 따라 활성화 상태를 결정하는 함수
    const isActive = (path: string) => {
        if (currentPath.includes('complete')) {
            return path === 'cart' || path === 'checkout' || path === 'complete' // 모든 단계 활성화
        }
        if (currentPath.includes('checkout')) {
            return path === 'cart' || path === 'checkout' // 장바구니, 결제 활성화
        }
        return path === 'cart' // 장바구니만 활성화
    }

    return (
        <MainFrame
            title={
                <div className={styles.header}>
                    {['cart', 'checkout', 'complete'].map((path, index) => (
                        <React.Fragment key={path}>
                            <div
                                className={isActive(path) ? styles.active : ''}
                            >
                                {path === 'cart'
                                    ? '장바구니'
                                    : path === 'checkout'
                                    ? '주문/결제'
                                    : '결제완료'}
                            </div>
                            {index < 2 && (
                                <div>
                                    <i
                                        className={`icon-angle-double-right ${
                                            isActive(path) ? styles.active : ''
                                        }`}
                                    ></i>
                                </div>
                            )}
                        </React.Fragment>
                    ))}
                </div>
            }
            hideSiteName={true}
            maxWidth={orderPath == 'complete' ? 800 : undefined}
        >
            {orderPath === 'cart' && <CartList />}
            {orderPath === 'checkout' && <OrderCheckOut />}
            {orderPath === 'complete' && <OrderComplete />}
        </MainFrame>
    )
}

export default OrderPage
