import { onErrorImg } from '@Util/imageUtils';
import React, { CSSProperties, useState } from 'react';

type Booleanish = boolean | "true" | "false";

const ImageTag = (props: {
    src: string,
    alt: string,
    fallbackSrc?: string, // 대체 이미지 소스
    onClick?: () => void,
    style?: CSSProperties,
    className?: string,
    draggable?: Booleanish
    onError?:()=>void
}) => {
    const [imgSrc, setImgSrc] = useState(props.src);

    const handleError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
        onErrorImg(e); // 기존 에러 핸들링
        if (props.fallbackSrc) {
            setImgSrc(props.fallbackSrc); // 대체 이미지로 변경
        }
        if(props.onError){
            console.log(e)
            props.onError()
        }
    };

    return (
        <img
            src={imgSrc}
            alt={props.alt}
            onClick={props.onClick}
            onError={handleError}
            style={props.style}
            className={props.className}
            draggable={props.draggable || 'true'}
        />
    );
};

export default ImageTag;
