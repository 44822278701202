import React, { useEffect, useRef } from 'react'
import styles from './ImageDrop.module.scss'
import { convertToPng, isPng } from '@Util/imageUtils'
import { getModalInstance } from '@Component/common/Modal/ModalProvider'

interface DropAreaProps {
    fileMap: Map<number, File>
    setFileMap: React.Dispatch<React.SetStateAction<Map<number, File>>>
    maxFiles?: number // Maximum number of files allowed
}

const DropArea = ({ fileMap, setFileMap, maxFiles = 10 }: DropAreaProps) => {
    const modal = getModalInstance()
    const galleryRef = useRef<HTMLDivElement>(null)
    const hoverViewRef = useRef<HTMLDivElement>(null)

    const addFiles = async (files: FileList) => {
        let newFileMap = new Map(fileMap)

        for (let i = 0; i < files.length; i++) {
            const file = files[i]
            if (!/^image\//.test(file.type)) {
                modal?.alert(
                    () => {},
                    '알림',
                    '이미지만 업로드 가능합니다.',
                    ''
                )
                continue
            }

            const processedFile = isPng(file) ? file : await convertToPng(file)

            if (newFileMap.size >= maxFiles) {
                modal?.confirm(
                    () => {
                        // 교체 시 마지막 항목 제거
                        const lastKey = Math.max(...newFileMap.keys())
                        newFileMap.delete(lastKey)
                        newFileMap.set(newFileMap.size, processedFile)
                    },
                    '확인',
                    '이미지 최대 개수를 초과했습니다.',
                    '마지막 이미지를 교체하시겠습니까?'
                )
            } else {
                newFileMap.set(newFileMap.size, processedFile)
            }
        }
        setFileMap(newFileMap)
    }

    const delFile = (num: number) => {
        const newFileMap = new Map(fileMap)
        newFileMap.delete(num)
        setFileMap(newFileMap)
    }

    const updateFileNames = () => {
        const updatedFileMap = new Map<number, File>()
        let index = 0
        let isUpdateNeeded = false

        for (const [key, file] of fileMap) {
            const expectedFileName = `${index}.png`

            // 파일 이름이 기대 값과 다르면 업데이트 필요
            if (file.name !== expectedFileName) {
                isUpdateNeeded = true
            }

            // 새 파일 생성 및 추가
            const renamedFile = new File([file], expectedFileName, {
                type: file.type,
            })
            updatedFileMap.set(index, renamedFile)
            index++
        }

        if (isUpdateNeeded) {
            setFileMap(updatedFileMap)
        }
    }

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault()
        addFiles(event.dataTransfer.files)
    }

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault()
    }

    const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault()
    }

    useEffect(() => {
        const dataTransfer = new DataTransfer()
        fileMap.forEach((file) => {
            dataTransfer.items.add(file)
        })
        addFiles(dataTransfer.files)
    }, [])

    useEffect(() => {
        updateFileNames()
    }, [fileMap])

    return (
        <div
            className={styles.dropArea}
            onDragEnter={handleDragOver}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
        >
            <p>여기 이미지를 놔두세요</p>
            <input
                type="file"
                id="fileElem"
                multiple
                accept="image/*"
                style={{ display: 'none' }}
                onChange={(e) => addFiles(e.target.files!)}
            />
            <label className={styles.selectImage} htmlFor="fileElem">
                파일 선택하기
            </label>
            <div className={styles.gallery} ref={galleryRef}>
                {Array.from(fileMap.entries()).map(([num, file]) => (
                    <div
                        key={num}
                        className={styles.galleryItem}
                        data-num={num}
                    >
                        <img
                            src={URL.createObjectURL(file)}
                            alt={`image-${num}`}
                            onLoad={(e) =>
                                URL.revokeObjectURL(
                                    (e.target as HTMLImageElement).src
                                )
                            }
                        />
                        <i
                            className={`${styles.removeImage} icon-trash`}
                            onClick={() => delFile(num)}
                        ></i>
                    </div>
                ))}
            </div>
            <div className={styles.hoverView} ref={hoverViewRef}></div>
        </div>
    )
}

export default DropArea
