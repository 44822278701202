import {Privacy} from '@Component/domains/cmm/Document/Privacy/Privacy'
import React from 'react'
import MainFrame from "@Component/layout/MainFrame";

function PrivacyPage() {

    return (
        <MainFrame title={'개인정보처리방침'} maxWidth={1000} documentTitle={'개인정보처리방침'}>
            <Privacy />
        </MainFrame>
    )
}

export default PrivacyPage
