import { useEffect, useState } from 'react'
import {
    checkUserInfo, kakaoLogin, naverLogin,
    preventLoggedInAccess,
} from '@Service/user/UserService'
import { Link } from 'react-router-dom'
import ImageTag from '@Component/common/Image/ImageTag'
import kakaoApiImage from '@Image/Api/kakao_short.png'
import naverApiImage from '@Image/Api/btnG_완성형.png'
import MainFrame from '@Component/layout/MainFrame'
import styles from '@Css/form.module.scss'
import FormField, { FormFieldProps } from '@Component/layout/FormField'

import { createUserLoginDto, UserDto } from '@Types/user'
import { formList } from '@Page/user/account/UserLoginPage/form'
import { userLoginApi} from '@Api/user/account'
import modal from "@Component/common/Modal/Modal";
import {getModalInstance} from "@Component/common/Modal/ModalProvider";

const UserLoginPage = (): JSX.Element => {
    const [loginDto, setLoginDto] = useState<UserDto.Login>(createUserLoginDto)
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');
    const submit = () => {

        userLoginApi(loginDto)
    }
    useEffect(() => {
        const modal = getModalInstance();
        if(code=='409'){
            modal?.alert(()=>{},'알림','계정정보가 존재합니다.','마이페이지에서 연동해주세요.')
        }
    }, [code]);


    return (
        <MainFrame maxWidth={300} className={styles.form} title={'로그인'}>
            <FormField
                formFieldList={formList}
                data={loginDto}
                setData={setLoginDto}
                onKeyDown={submit}
            />
            <input
                type="button"
                value="로그인"
                onClick={submit}
                className={styles.grayButton}
            />
            <div className={styles.kakaoImage}
                 onClick={() => kakaoLogin()}>
                <ImageTag
                    src={kakaoApiImage}
                    alt="kakao 로그인"
                />
            </div>
            <div className={styles.naverImage}
                 onClick={() => naverLogin()}>
                <ImageTag
                    src={naverApiImage}
                    alt="naver 로그인"
                />
            </div>
            <div className={styles.flex}>
                <Link to={'/user/join'}>회원가입</Link>
                <Link to={'/user/find/id'}>아이디/비밀번호 찾기</Link>
            </div>
        </MainFrame>
    )
}

export default UserLoginPage
