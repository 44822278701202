import {HttpAlert, HttpSuccess} from "@Types/cmm/HttpCallBack";
import {getModalInstance} from "@Component/common/Modal/ModalProvider";
import {CategoryDto, CategoryList, createCategoryInsertDto, createCategoryUpdateSdDto} from "@Types/category";
import {deleteRequest, postRequest, putRequest} from "@Api/Api";

import React from "react";
import {getCategoryListApi} from "@Api/cmm";

const httpAlert: HttpAlert = {}


export const updatePmCategory = (primaryCategory: string, setCategoryList: React.Dispatch<React.SetStateAction<CategoryList>>) => {
    const modal = getModalInstance()

    modal?.prompt(
        async (afterPrimaryNameTemp: string) => {
            if (afterPrimaryNameTemp === '') {
                modal?.alert(
                    () => {
                    },
                    '알림',
                    '카테고리 이름을 입력해주세요'
                )
            } else if (afterPrimaryNameTemp != null) {
                const categoryDTO: CategoryDto.UpdatePm = {
                    afterName: afterPrimaryNameTemp,
                    beforeName: primaryCategory,
                }

                const httpSuccess: HttpSuccess = {
                    200: () => {
                        modal?.alert(
                            async () => {
                                setCategoryList(await getCategoryListApi(true))
                            },
                            '알림',
                            '카테고리가 성공적으로 수정되었습니다.'
                        )
                    },
                }
                await putRequest(
                    '/admin/product/category/primary',
                    categoryDTO,
                    httpSuccess
                )
            }
        },
        () => {
        },
        '입력',
        '변경할 이름을 입력해주세요.'
    )

}


export const insertPmCategory = (setCategoryList: React.Dispatch<React.SetStateAction<CategoryList>>) => {
    const modal = getModalInstance()

    modal?.prompt(
        (primaryName: string) => {
            if (primaryName === '') {
                modal?.alert(() => {
                }, '알림', '카테고리 이름을 입력해주세요')
                return false
            }
            modal?.prompt(
                (secondName: string) => {
                    if (secondName === '') {
                        modal?.alert(
                            () => {
                            },
                            '알림',
                            '카테고리 이름을 입력해주세요'
                        )
                    } else if (primaryName != null && secondName != null) {
                        let category: CategoryDto.Insert = {
                            primaryName: primaryName,
                            secondaryName: secondName,
                        }

                        const httpSuccess: HttpSuccess = {
                            200: () => {
                                modal?.alert(
                                    async () => {
                                        setCategoryList(await getCategoryListApi(true))
                                    },
                                    '알림',
                                    '카테고리가 성공적으로 추가되었습니다.'
                                )
                            },
                        }

                        postRequest(
                            '/admin/product/category',
                            category,
                            httpSuccess
                        )
                    }
                },
                () => {
                },
                '입력',
                '2차 카테고리 이름을 입력해주세요'
            )
        },
        () => {
        },
        '입력',
        '1차 카테고리 이름을 입력해주세요.'
    )
}

export const deletePmCategory = (primaryCategory: string, setCategoryList: React.Dispatch<React.SetStateAction<CategoryList>>) => {
    const modal = getModalInstance()

    modal?.confirm(
        async () => {
            const httpSuccess: HttpSuccess = {
                200: () => {
                    modal?.alert(
                        async () => {
                            setCategoryList(await getCategoryListApi(true))
                        },
                        '알림',
                        '카테고리가 성공적으로 삭제되었습니다.'
                    )
                },
            }
            await deleteRequest(
                '/admin/product/category/primary/' + primaryCategory,
                httpSuccess,
                httpAlert,
                {}
            )
        },
        '알림',
        '해당 카테고리 상품이 모두 비공개 됩니다.\n삭제하시겠습니까?'
    )

}

export const updateSdCategory = (categoryId:string , setCategoryList: React.Dispatch<React.SetStateAction<CategoryList>>) => {
    const modal = getModalInstance()
    const updateDto: CategoryDto.UpdateSd = createCategoryUpdateSdDto()
    updateDto.categoryId = categoryId
    modal?.prompt(
        (secondaryNameTemp: string) => {
            if (secondaryNameTemp === '') {
                modal?.alert(
                    () => {
                    },
                    '알림',
                    '카테고리 이름을 입력해주세요.'
                )
            } else if (secondaryNameTemp != null) {
                updateDto.secondaryName = secondaryNameTemp

                const httpSuccess: HttpSuccess = {
                    200: () => {
                        modal?.alert(
                            async () => {
                                setCategoryList(await getCategoryListApi(true))
                            },
                            '알림',
                            '카테고리가 성공적으로 수정되었습니다.'
                        )
                    },
                }

                putRequest(
                    '/admin/product/category/second',
                    updateDto,
                    httpSuccess
                )
            }
        },
        () => {
        },
        '입력',
        '변경할 이름을 입력해주세요.'
    )

}

export const updateCategoryOrder = (category : CategoryDto.Response, setCategoryList: React.Dispatch<React.SetStateAction<CategoryList>>) => {
    const modal = getModalInstance()
    const updateDto: CategoryDto.UpdateSd = createCategoryUpdateSdDto()
    updateDto.categoryId =category.categoryId;
    updateDto.secondaryName =category.secondaryName;
    modal?.prompt(
        (orderTemp: string) => {
            if (isNaN(parseInt(orderTemp))) {
                modal?.alert(
                    () => {
                    },
                    '알림',
                    '변경값을 입력해주세요'
                )
            } else {
                updateDto.menuOrderSd = parseInt(orderTemp)
                const httpSuccess: HttpSuccess = {
                    200: () => {
                        modal?.alert(
                            async () => {
                                setCategoryList(await getCategoryListApi(true))
                            },
                            '알림',
                            '카테고리가 성공적으로 수정되었습니다.'
                        )

                    },
                }
                putRequest(
                    '/admin/product/category/second',
                    updateDto,
                    httpSuccess
                )
            }
        },
        () => {
        },
        '입력',
        '변경할 순서를입력해주세요.', '현재순서 : ' + category.menuOrderSd
    )
}
export const insertSdCategory = (primaryCategory: string, setCategoryList: React.Dispatch<React.SetStateAction<CategoryList>>) => {
    const modal = getModalInstance()
    const insertDto: CategoryDto.Insert = createCategoryInsertDto();
    insertDto.primaryName = primaryCategory;
    modal?.prompt(
        (secondaryNameTemp: string) => {
            if (secondaryNameTemp != null) {
                insertDto.secondaryName = secondaryNameTemp

                const httpSuccess: HttpSuccess = {
                    200: () => {
                        modal?.alert(
                            async () => {
                                setCategoryList(await getCategoryListApi(true))
                            },
                            '알림',
                            '카테고리가 성공적으로 추가되었습니다.'
                        )
                    },
                }
                postRequest(
                    '/admin/product/category',
                    insertDto,
                    httpSuccess
                )
            }
        },
        () => {
        },
        '입력',
        '추가할 이름을 입력해주세요.'
    )
}

export const deleteSdCategory = (categoryId: string
    , setCategoryList: React.Dispatch<React.SetStateAction<CategoryList>>) => {
    const modal = getModalInstance()

    modal?.confirm(
        async () => {
            const httpSuccess: HttpSuccess = {
                200: () => {
                    modal?.alert(
                        async () => {
                            setCategoryList(await getCategoryListApi(true))
                        },
                        '알림',
                        '카테고리가 성공적으로 삭제되었습니다.'
                    )
                },
            }
            deleteRequest(
                '/admin/product/category/second/' + categoryId,
                httpSuccess
            )
        },
        '알림',
        '해당 카테고리 상품이 모두 비공개 됩니다.\n삭제하시겠습니까?'
    )
}
