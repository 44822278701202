import DashboardBuilder from '@Component/layout/GridDashBoard/DashboardBuilder'
import { useEffect, useState } from 'react'
import { getSiteSummery } from '@Api/cmm'
import { Analytics } from '@Component/domains/cmm/DashBoard/Analytics'
import { ProductSummery } from '@Component/domains/cmm/DashBoard/ProductSummery'
import { AnalyticsGraph } from '@Component/domains/cmm/DashBoard/AnalyticsGraph'
import { CmmDto } from '@Types/cmm'
import { OrderSummery } from '@Component/domains/cmm/DashBoard/OrderSummery'

const AdminDashBoardPage = () => {
    const [summery, setSummery] = useState<CmmDto.Summery>()

    useEffect(() => {
        async function fetchData() {
            setSummery(await getSiteSummery())
        }

        fetchData()
    }, [])

    const items = [
        { id: 1, x: 4, y: 4, component: <OrderSummery summery={summery} /> },
        {
            id: 2,
            x: 2,
            y: 5,
            component: <AnalyticsGraph summery={summery} />,
        },
        {
            id: 3,
            x: 2,
            y: 5,
            component: <Analytics summery={summery} />,
        },
        {
            id: 4,
            x: 2,
            y: 5,
            component: <ProductSummery summery={summery} type={'day'} />,
        },
        {
            id: 5,
            x: 2,
            y: 5,
            component: <ProductSummery summery={summery} type={'mon'} />,
        },
    ]
    return <DashboardBuilder items={items} />
}

export default AdminDashBoardPage
