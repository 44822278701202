import {UserDto} from '@Types/user'
import {useEffect, useState} from 'react'
import {checkUserInfo, kakaoLogin, naverLogin} from '@Service/user/UserService'
import Button from '@Component/common/Input/Button'
import {getModalInstance} from '@Component/common/Modal/ModalProvider'
import styles from './index.module.scss'
import MainFrame from '@Component/layout/MainFrame'
import UserOrderInfo from '../UserOrderInfo'
import {useNavigate} from 'react-router-dom'
import {userDeleteApi} from "@Api/user/account";
import ImageTag from "@Component/common/Image/ImageTag";
import kakaoApiImage from "@Image/Api/kakao_short.png";
import naverApiImage from "@Image/Api/btnG_완성형.png";

export const UserInfoView = (props: {
    isMypage?: boolean
    isAdmin?: boolean
    userInfo?: UserDto.Info
}) => {
    const modal = getModalInstance()
    const navigete = useNavigate()
    const [userInfo, setUserInfo] = useState<UserDto.Info>()

    const fetchData = async () => {
        if (props.userInfo) {
            setUserInfo(props.userInfo)
        } else {
            await checkUserInfo(setUserInfo, true)
        }
    }

    useEffect(() => {
        fetchData()
    }, [props.userInfo])

    if (!userInfo) {
        return <></>
    }

    return (
        <MainFrame className={styles.userInfo}>
            <div>
                <div>
                    <span>이름 :</span> {userInfo?.userName}
                </div>
                <div>
                    <span>전화번호 :</span> {userInfo?.phoneNumber}
                </div>
                <div>
                    <span>이메일 :</span> {userInfo?.email}
                </div>
            </div>

            {props.isMypage && (
                <div>
                    <Button
                        name={'소셜 계정연동'}
                        onClick={() => {
                            modal?.popup(
                                <div>
                                    <div className={styles.kakaoImage}
                                         onClick={() => {
                                             modal?.confirm(
                                                 () => {
                                                     kakaoLogin(true)
                                                 },
                                                 '확인',
                                                 '계정연동시 계정정보가 변경됩니다.',
                                                 '기존 아이디로 로그인이 불가해집니다<br/>연동하시겠습니까?'
                                             )
                                         }
                                         }>
                                        <ImageTag
                                            src={kakaoApiImage}
                                            alt="kakao 로그인"
                                        />
                                    </div>
                                    <div className={styles.naverImage}
                                         onClick={() => {
                                             modal?.confirm(
                                                 () => {
                                                     naverLogin(true)
                                                 },
                                                 '확인',
                                                 '계정연동시 계정정보가 변경됩니다.',
                                                 '기존 아이디로 로그인이 불가해집니다<br/>연동하시겠습니까?'
                                             )
                                         }
                                         }>
                                        <ImageTag
                                            src={naverApiImage}
                                            alt="naver 로그인"
                                        />
                                    </div>
                                </div>
                                , '400px'
                            )

                        }}
                    />
                    <Button
                        name={'회원정보수정'}
                        onClick={() => {
                            navigete('/user/update')
                        }}
                    />
                    <Button
                        name={'회원탈퇴'}
                        onClick={() => {
                            modal?.prompt(
                                (input) => {
                                    if (
                                        input ==
                                        process.env.SITE_NAME +
                                        '회원을 탈퇴합니다.'
                                    ) {
                                        userDeleteApi()
                                    }
                                },
                                () => {
                                },
                                '확인',
                                '회원 탈퇴하시겠습니까?<br/>아래문구를 입력해주세요.',
                                process.env.SITE_NAME + '회원을 탈퇴합니다.'
                            )
                        }}
                    />
                </div>
            )}
            {props.isAdmin && (
                <div>
                    <Button
                        name={'최근주문정보'}
                        onClick={() => {
                            modal?.popup(
                                <UserOrderInfo
                                    userId={userInfo.userId}
                                    isAdmin={true}
                                />,
                                '1000px'
                            )
                        }}
                    />
                </div>
            )}
        </MainFrame>
    )
}

export default UserInfoView
