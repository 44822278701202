import {ValidationOption} from "@Util/formUtils";

export const addressValid: ValidationOption[] = [

    {
        name: 'addressNickName',
        baseMessage: '배송지 별칭을',
        successMessage : ' '
    },
    {
        name: 'address',
        baseMessage: '주소를',
        nullAbleMessage: '주소를 검색해주세요.',
        successMessage: ' '
    },
    {
        name: 'addressDetail',
        baseMessage: '주소를',
        customValidTarget: 'address',
        nullAbleMessage: '상세주소를 입력해주세요.',
        successMessage: ' '
    },
    {
        name: 'recipientName',
        baseMessage: '수령인 이름을',
        successMessage: ' '

    },
    {
        name: 'recipientContact',
        baseMessage: '수령인 연락처를',
        regex: /^[0-9]{3}-[0-9]{4}-[0-9]{4}$/, // 전화번호 형식 검사 정규식
        regexMessage: '유효한 전화번호 형식이 아닙니다. (예: 010-1234-5678)',
        successMessage: ' '
    },
    {
        name: 'deliveryRequest',
        baseMessage: '배송요청사항을',
        nullAbleMessage: '배송요청사항을 선택해주세요.',
        customValid: data => {
            console.log(data)
            return !(data?.deliveryRequest=='직접입력' && data?.deliveryRequestDetail == '') ;
        },
        customValidMessage :'배송요청사항을 입력해주세요.',
        successMessage : ' '
    },
]