import {getModalInstance} from "@Component/common/Modal/ModalProvider";
import {HttpAlert, HttpSuccess} from "@Types/cmm/HttpCallBack";
import {deleteRequest, getRequest, postRequest} from "@Api/Api";
import {ProductDto} from "@Types/product/productDto";
// import {ProductCheck, ProductDetail, ProductListResponse, ProductSearchDTO} from "@Types/product/productDto";

export const saveProductApi = async (formData: FormData, freeView?: boolean) => {
    const modal = getModalInstance()

    const httpSuccess: HttpSuccess = {
        200: () => {
            if (!freeView) {
                modal!.alert(
                    () => {
                        window.location.href = '/admin/product/list'
                    },
                    '알림',
                    '상품이 성공적으로 저장되었습니다.'
                )
            }
        },
    }
    const httpAlert: HttpAlert = {}

    try {
        await postRequest('/admin/product', formData, httpSuccess, httpAlert, {
            headers: {
                'Content-Type': 'multipart/form-data', // 멀티파트 요청의 Content-Type 지정
            },
        })
    } catch (e) {
        console.log(e)
    }
}

export const getAdminProductList = async (
    searchQuery: ProductDto.Search
) => {
    const modal = getModalInstance()
    try {
        const httpAlert: HttpAlert = {}
        const httpSuccess: HttpSuccess = {
            204: () => {
                modal!.alert(() => {
                }, '알림', '조회된 상품이 없습니다.')
            },
        }

        const config = {
            params: searchQuery,
        }
        return await getRequest<ProductDto.Page>(
            '/admin/product/list',
            httpSuccess,
            httpAlert,
            config
        )
    } catch (e) {
        console.log(e)
    }
}

export const CheckDuplicate = async (
    type: string,
    productForm: ProductDto.Duplication,
): Promise<boolean> => {
    try {
        await postRequest(
            `/admin/product/check/${type}`,
            productForm
        )
        return true
    } catch (e) {
        return false
    }
}

export const getAdminProduct = async (productId: string) => {
    try {
        const httpAlert: HttpAlert = {}
        const httpSuccess: HttpSuccess = {}

        return await getRequest<ProductDto.Response>(
            '/admin/product/' + productId,
            httpSuccess,
            httpAlert,
            {}
        )
    } catch (e) {
        console.log(e)
    }
}

export const deleteProductApi = async (productId: string) => {
    try {
        const modal = getModalInstance();
        const httpAlert: HttpAlert = {}
        const httpSuccess: HttpSuccess = {
            200: () => {
                modal?.alert(() => {
                    window.location.href = '/admin/product/list'
                }, '알림', '상품이 삭제되었습니다.')
            }
        }

        return await deleteRequest<ProductDto.Response>(
            '/admin/product/' + productId,
            httpSuccess,
            httpAlert,
            {}
        )
    } catch (e) {
        console.log(e)
    }
}

