// productFormList.ts

import {FormFieldProps} from 'components/layout/FormField';
import {setNumber, setPhone} from "@Util/formUtils";
import {bankOptions, carrierOptions, stateOptions} from "@Types/order";
import {paymentType} from "@Component/domains/order/OrderCheckOut/Payment/form";


export const formList: FormFieldProps[] = [
    {
        label: '주문 정보',
        inputType: 'subTitle'
    },
    {
        label: '주문명',
        placeholder: 'ORDER NAME',
        inputType: 'text',
        name: 'orderName'

    },
    {
        label: '주문상태',
        placeholder: '-주문상태를 선택해주세요-',
        inputType: 'select',
        options: stateOptions('Insert'),
        name: 'orderState'
    },
    {
        label: '주문일자',
        placeholder: 'ORDER DATE',
        inputType: 'date',
        name: 'orderDate',
    },
    {
        label: '결제 정보',
        inputType: 'subTitle'
    },
    {
        label: '결제금액',
        placeholder: 'PAYMENT AMOUNT',
        inputType: 'text',
        name: 'paymentAmount',
        onChange: event => setNumber(event)
    },
    {
        label: '결제수단',
        placeholder: '-결제수단을 선택해주세요-',
        inputType: 'select',
        name: 'paymentType',
        options: paymentType
    },
]
export const formList2: FormFieldProps[] = [
    {
        label: '입금은행',
        placeholder: '-은행을 선택해주세요-',
        inputType: 'select',
        options: bankOptions,
        name: 'accountCode',
    },
    {
        label: '입금자명',
        placeholder: 'ACCOUNT HOLDER',
        inputType: 'text',
        name: 'accountHolder',
    },
    {
        label: '계좌번호',
        placeholder: 'ACCOUNT NUMBER',
        inputType: 'text',
        name: 'accountNumber',
    },]
export const formList3: FormFieldProps[] = [
    {
        label: '배송 정보',
        inputType: 'subTitle'
    },
    {
        label: '배송사',
        placeholder: '-배송사를 선택해주세요-',
        options: carrierOptions,
        inputType: 'select',
        name: 'deliveryCode',
    },
    {
        label: '송장번호',
        placeholder: 'DELIVERY NUMBER',
        inputType: 'text',
        name: 'deliveryNumber',
    },

    {
        label: '배송지 주소',
        placeholder: 'ADDRESS',
        inputType: 'text',
        name: 'address',
    },
    {
        label: '수령인 이름',
        placeholder: 'RECIPIENT NAME',
        inputType: 'text',
        name: 'recipientName',
    },
    {
        label: '수령인 연락처',
        placeholder: 'RECIPIENT CONTACT',
        inputType: 'text',
        name: 'recipientContact',
        onChange: event => setPhone(event)
    },
    {
        label: '배송요청사항',
        placeholder: 'DELIVERY REQUEST',
        inputType: 'text',
        name: 'deliveryRequest',
    },
    {
        label: '',
        inputType: 'subTitle'
    },
];
