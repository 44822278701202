import React, {useEffect, useState} from "react"

import {MainImageServiceApi} from "@Api/cmm";
import {getFileMap} from "@Util/imageUtils";
import MainImage from "@Component/domains/cmm/MainImage/MainImage";
import ProductListPage from "@Page/user/product/ProcutListPage";
import ReviewList from "@Component/domains/board/List/ReviewList";
import {CategoryList} from "@Types/category/categoryDto";
import MainFrame from "@Component/layout/MainFrame";
import ViewChangeButton from "@Component/domains/board/ViewChangeButton";
import {useListView} from "../../../../context/board/ListViewContext";

function IndexPage() {
    const {setViewMode} = useListView()
    const [categoryList, setCategoryList] = useState<CategoryList>({})

    const [fileMap, setFileMap] = useState<Map<number, File>>()

    const fetchFileMap = async () => {
        const imageList = await MainImageServiceApi()
        if (imageList) {
            setFileMap(await getFileMap(imageList))
        }
    }

    useEffect(() => {
        fetchFileMap()

        document.title = 'BROSPO'
        setViewMode('grid')
    }, [])

    return (
        <>
            <MainImage fileMap={fileMap}/>
            <ProductListPage/>
            <MainFrame title={'상품리뷰'} documentTitle={'상품리뷰'}  maxWidth={1400}  >
                <ReviewList/>
            </MainFrame>
        </>
    )
}

export default IndexPage
