import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {getModalInstance} from "@Component/common/Modal/ModalProvider";
import {freeView, saveProduct} from "@Service/product/ProductService";
import {getFileMap} from "@Util/imageUtils";
import {deleteProductApi, getAdminProduct} from "@Api/admin/product";
import MainFrame, {ButtonOption} from "@Component/layout/MainFrame";
import styles from "@Css/form.module.scss";
import FormField from "@Component/layout/FormField";

import {formList} from "@Page/admin/product/AdminProductPage/form";
import {mapResponseToSave, ProductDto} from "@Types/product/productDto";
import {createProductSaveDto} from "@Types/product/default";
import CategorySelector from "@Component/domains/product/ProductInfo/CategorySelector";
import SizeSelector from "@Component/domains/product/ProductInfo/SizeSelector";

import ImageDrop from "@Component/common/Image/ImageDrop/ImageDrop";
import {Editor} from "@Component/common/Input/Editor/Editor";
import {saveValid} from "@Page/admin/product/AdminProductPage/valid";
import {isValid} from "@Util/formUtils";
import Button from "@Component/common/Input/Button";

const AdminProductPage = () => {
    const [fileMap, setFileMap] = useState<Map<number, File>>(new Map())
    const [saveDto, setSaveDto] = useState<ProductDto.Save>(createProductSaveDto)
    const [defaultData, setDefaultData] = useState<string>()
    const {productId} = useParams()
    const modal = getModalInstance()


    const fetchData = async () => {
        if (productId) {
            const response = await getAdminProduct(productId)
            setSaveDto(mapResponseToSave(response || {}));
            setFileMap(await getFileMap(response?.imageUrl || []));
            setDefaultData(response?.productDescription || '')
        }
    }

    useEffect(() => {
        fetchData()
    }, [productId])

    const actionButton: ButtonOption[] = [{
        name: '상품삭제',
        onClick: () => {
            modal?.confirm(async () => {
                await deleteProductApi(productId!)
            }, '확인', '상품이 완전히 삭제됩니다 삭제하시겠습니까?', '장바구니, 위시리스트, 리뷰, 문의 등 삭제')
        }

    }]

    return (
        <MainFrame className={styles.form} maxWidth={900} title={
            `상품 ${productId ? '수정' : '등록'}`
        } documentTitle={`상품 ${productId ? '수정' : '등록'}`} action={productId ? actionButton : []}>
            <FormField formFieldList={formList} data={saveDto} setData={setSaveDto} validOption={saveValid}/>
            <CategorySelector data={saveDto} setData={setSaveDto}/>
            <h3>사이즈 선택</h3>
            <SizeSelector
                data={saveDto}
                setData={setSaveDto}
            />


            <h3>메인 이미지 설정</h3>
            <ImageDrop
                fileMap={fileMap}
                setFileMap={setFileMap}
            />


            <h3>상품 설명</h3>
            <Editor name={'productDescription'}
                    data={saveDto}
                    setData={setSaveDto}
                    defaultData={defaultData}
            />

            <div className={styles.flex}>
                <Button
                    extraClass={styles.halfButton} name={'미리보기'}
                    onClick={() => {
                        freeView(saveDto, fileMap)
                    }}/>

                <Button
                    extraClass={styles.halfButton}
                    name={productId ? '수정하기' : '등록하기'}
                    onClick={async () => {
                        if (await isValid(saveDto, saveValid, true)) {
                            saveProduct(saveDto, fileMap)
                        }
                    }}
                />
            </div>
        </MainFrame>
    )
}

export default AdminProductPage;
