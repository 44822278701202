// OrderDeliveryInfo.tsx
import React from 'react'
import { AbleStatuses, carrierNameMapping, OrderDto } from '@Types/order'
import { AddressDto } from '@Types/address'
import MainFrame from '@Component/layout/MainFrame'
import { getModalInstance } from '@Component/common/Modal/ModalProvider'
import { AddressForm } from '@Component/domains/address/List'
import styles from '@Component/domains/order/OrderInfo/index.module.scss'
import { decodeHTML } from '@Util/stringUtils'
import OrderEdit from '@Component/domains/order/OrderEdit'
import { DeliveryTracker } from '@Component/common/DeliveryTracker/DeliveryTracker'

interface Props {
    orderDto?: OrderDto.Response
    addressDto?: AddressDto.Response
    setAddressDto: React.Dispatch<
        React.SetStateAction<AddressDto.Response | undefined>
    >
    isAdmin?: boolean
    setRefresh?: React.Dispatch<React.SetStateAction<boolean>>
}

const OrderDeliveryInfo: React.FC<Props> = ({
    orderDto,
    addressDto,
    setAddressDto,
    isAdmin,
    setRefresh,
}) => {
    const modal = getModalInstance()

    return (
        <MainFrame
            className={`${styles.infoFlex} ${styles.info}`}
            title="배송정보"
            hideSiteName={true}
            action={
                isAdmin
                    ? [
                          {
                              name: '배송 정보 수정',
                              onClick: () => {
                                  modal?.popup(
                                      <OrderEdit
                                          orderDto={orderDto}
                                          updateType={'delivery'}
                                      />,
                                      '900px',
                                      () => {
                                          if (setRefresh) setRefresh(true)
                                      }
                                  )
                              },
                          },
                      ]
                    : [
                          {
                              name: '배송 정보 수정',
                              onClick: () => {
                                  console.log(orderDto?.orderState)
                                  if (
                                      !AbleStatuses.addressReq.includes(
                                          orderDto?.orderState || ''
                                      )
                                  ) {
                                      modal?.alert(
                                          () => {},
                                          '알림',
                                          '주문진행중에는 배송지 변경이 불가합니다',
                                          '관리자에게 문의해주세요.'
                                      )
                                      return
                                  }
                                  modal?.popup(
                                      <AddressForm setAddress={setAddressDto} />
                                  )
                              },
                          },
                      ]
            }
        >
            {addressDto || orderDto?.recipientName ? (
                <>
                    <div>
                        <p>수령인</p>{' '}
                        <span>
                            {addressDto?.recipientName ||
                                orderDto?.recipientName}
                        </span>
                    </div>
                    <div>
                        <p>전화번호</p>
                        <span>
                            {addressDto?.recipientContact ||
                                orderDto?.recipientContact}
                        </span>
                    </div>
                    <div>
                        <p>주소</p>
                        <span>
                            {decodeHTML(
                                addressDto
                                    ? `${addressDto?.address} ${addressDto?.addressDetail}`
                                    : `${orderDto?.address} ${orderDto?.addressDetail}`
                            )}
                        </span>
                    </div>
                    <div>
                        <p>배송요청사항</p>
                        <span>
                            {addressDto?.deliveryRequest ||
                                orderDto?.deliveryRequest}
                        </span>
                    </div>
                    {orderDto?.deliveryNumber && (
                        <div>
                            <p>배송조회</p>
                            <span
                                className={styles.link}
                                onClick={() => {
                                    modal?.popup(
                                        <DeliveryTracker
                                            deliveryCode={
                                                orderDto?.deliveryCode!
                                            }
                                            deliveryNumber={
                                                orderDto?.deliveryNumber!
                                            }
                                        />
                                    )
                                }}
                            >
                                [
                                {
                                    carrierNameMapping[
                                        orderDto?.deliveryCode || ''
                                    ]
                                }
                                ] {orderDto.deliveryNumber}
                            </span>
                        </div>
                    )}
                </>
            ) : (
                <div className="empty-view">배송지를 선택해주세요</div>
            )}
        </MainFrame>
    )
}

export default OrderDeliveryInfo
