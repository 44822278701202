import { ValidationOption } from '@Util/formUtils'
import { checkDuplicateApi } from '@Api/user/account'

export const findValid: ValidationOption[] = [
    {
        name: 'userName',
        baseMessage: '이름을',
        disableSuccessMessage: true,
    },
    {
        name: 'email',
        baseMessage: '이메일을',
        regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        disableSuccessMessage: true,
    },
    {
        name: 'email',
        baseMessage: '전화번호를',
        regex: /^[0-9]{3}-[0-9]{4}-[0-9]{4}$/, // 전화번호 형식 검사 정규식
        regexMessage: '유효한 전화번호 형식이 아닙니다. (예: 010-1234-5678)',
        disableSuccessMessage: true,
    },
]
