import ExcelJS from 'exceljs';

/**
 * 엑셀 파일을 생성하여 다운로드합니다.
 * @param {Array<Array<any>>} data - 엑셀에 포함될 데이터.
 * @param {string} sheetName - 시트 이름.
 * @param {string} fileName - 다운로드될 파일의 이름.
 */
export const generateExcel = async (
    data: Array<Array<any>>,
    sheetName: string,
    fileName: string
) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(sheetName);

    // Title 추가 (첫 번째 행 병합)
    const title = `${sheetName} - Generated Report`;
    const totalColumns = (data[0]?.length || 1) + 2; // 좌우 여백 포함
    worksheet.mergeCells(1, 1, 1, totalColumns);
    const titleCell = worksheet.getCell(1, 1);
    titleCell.value = title;
    titleCell.font = {bold: true, size: 16};
    titleCell.alignment = {horizontal: 'center', vertical: 'middle'};
    titleCell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {argb: 'F0F0F0'}, // 밝은 회색
    };

    // 상단 구분 셀 추가
    const topBorderRow = worksheet.addRow(
        Array(totalColumns).fill('_hr_')
    );
    topBorderRow.eachCell((cell) => {
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: '222222'}, // 더 밝은 회색
        }
        cell.value = ''
    });

    // 데이터 추가 (좌우 여백 포함)
    data.forEach((rowData) => {
        const row = worksheet.addRow(['_hr_', ...rowData, '_hr_']);
        row.eachCell((cell, colNumber) => {
            if (colNumber === 1 || colNumber === totalColumns) {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: '222222'}, // 더 밝은 회색
                }
                cell.value = ''
            } else if (typeof cell.value === 'string' && cell.value.includes('h_')) {
                // 강조 셀 스타일
                cell.font = {bold: true};
                cell.alignment = {horizontal: 'center', vertical: 'middle'};
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: 'F0F0F0'},
                };
                cell.value = cell.value.replace('h_', '');
            } else if (typeof cell.value === 'string' && cell.value.includes('_hr_')) {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: '222222'}, // 더 밝은 회색
                }
                cell.value = ''
            } else {
                // 기본 스타일 설정
                cell.alignment = {horizontal: 'center', vertical: 'middle'}
            }
        });
    });

    // 하단 구분 셀 추가
    const bottomBorderRow = worksheet.addRow(
        Array(totalColumns).fill('_hr_')
    );
    bottomBorderRow.eachCell((cell) => {
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: '222222'}, // 더 밝은 회색
        }
        cell.value = ''
    });

    // 열 너비 자동 조정
    worksheet.columns.forEach((column, index) => {
        if (index === 0 || index === worksheet.columns.length - 1) {
            // 첫 열 또는 마지막 열의 너비를 15로 고정
            column.width = 5;
        } else {
            const maxLength =
                column.values?.reduce<number>(
                    (max, value) => Math.max(max, String(value).length),
                    0
                ) || 0;

            // 다른 열의 너비 조정
            column.width = maxLength < 10 ? 10 * 1.3 : (maxLength + 2) * 1.5;
        }
    });

    worksheet.eachRow({ includeEmpty: true }, (row) => {
        row.height = 30; // 기본 높이 설정 (기본 높이의 1.5배 예시 값)
    });
    // 엑셀 파일 저장
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName + '.xlsx'; // 다운로드 파일명 설정
    link.click();
};
