import { setFileList } from "@Util/imageUtils";

import { ProductDto } from "@Types/product";
import { saveBoardApi } from "@Api/user/board";
import { saveAdminBoardApi } from "@Api/admin/board";

export const saveBoard = async (saveDto: ProductDto.Save,
    fileMap?: Map<number, File>, uploadFileMap?: Map<number, File>, isAdmin?: boolean) => {

    const formData = new FormData();
    if (fileMap) {
        setFileList(formData, fileMap, 'files')
    }
    if (uploadFileMap) {
        setFileList(formData, uploadFileMap, 'uploadFiles')

    }
    formData.append('saveDto', JSON.stringify(saveDto))
    isAdmin ? await saveAdminBoardApi(formData) : await saveBoardApi(formData)
}
