import React, { useEffect, useState } from 'react'
import { getModalInstance } from "@Component/common/Modal/ModalProvider";
import { deleteBoardApi, getBoardApi } from "@Api/user/board";
import { setWishicon } from "@Service/wish/WishService";

import { ProductListItem } from "@Component/domains/product/ProductList/ProductList";
import { BoardDto } from "@Types/board";
import { UserDto } from "@Types/user";
import { getUserInfo } from "@Api/user/account";
import MainFrame, { ButtonOption } from "@Component/layout/MainFrame";
import { ReviewEditor } from "@Component/domains/board/Edit/ReviewEditor";
import styles from "@Component/domains/board/View/index.module.scss";
import ReviewRating from "@Component/domains/board/ReviewRating";
import ImageViewList from "@Component/common/Image/ImageView/ImageViewList";
import { formatDate } from "@Util/dateUtile";
import { privacyName, stripHTMLTags } from "@Util/stringUtils";
import * as process from "process";
import FileUpload from '@Component/common/Input/FileUpload';
import { getFileMap } from '@Util/imageUtils';


export const NoticeView = (props: { boardId: string }) => {
    const [board, setBoard] = useState<BoardDto.Response>()
    const [fileMap, setFileMap] = useState<Map<number, File>>(new Map())

    const fetchData = async () => {
        if (props.boardId) {
            const response = await getBoardApi(props.boardId)
            if (response) {
                setBoard(response)
                setFileMap(await getFileMap(response?.fileUrl || []));

            }
        }
    }

    useEffect(() => {
        fetchData()
    }, [props.boardId])


    return (
        <MainFrame className={styles.boardView} title={'공지사항'} marginZero={true}>
            <label>
                공지 제목
                <div className={styles.info}>
                    <span>작성일 : {formatDate(board?.saveDate, 'yyyy/MM/dd HH:mm')}</span>
                </div>
            </label>
            <div className={styles.textarea}>{board?.title}</div>

            {fileMap.size != 0 && <label>첨부파일</label>}
            <FileUpload fileMap={fileMap} setFileMap={setFileMap} viewMode={true} />

            <label>
                공지 내용
                <div className={styles.info}>
                    <span>작성자 : {process.env.SITE_NAME}</span>
                </div>
            </label>
            <div
                dangerouslySetInnerHTML={{
                    __html: stripHTMLTags(board?.content?.replace('/board/', process.env.BOARD_IMAGE_BASE_URL!) || ''),
                }}
                className={styles.textarea + ' ' + styles.content}
            ></div>
        </MainFrame>
    )
}
