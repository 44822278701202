// OrderItemList.tsx
import React from 'react'
import {OrderDto} from '@Types/order'
import MainFrame from '@Component/layout/MainFrame'
import {getModalInstance} from '@Component/common/Modal/ModalProvider'
import {ProductListItem} from '@Component/domains/product/ProductList'
import styles from '@Component/domains/order/OrderInfo/index.module.scss'

interface Props {
    orderItemList?: OrderDto.TransformedOrder[]
}

const OrderItemList: React.FC<Props> = ({orderItemList}) => {
    const modal = getModalInstance()

    if (orderItemList?.length == 0) {
        return <></>
    }
    const clickHandler = (orderItem?: OrderDto.TransformedOrder) => {

        modal?.confirm(
            () => {
                window.location.href = '/product/order/detail/' + orderItem?.product?.productId
            },
            '확인',
            '상품을 확인하시겠습니까?',
            '페이지로 이동합니다.'
        )

    }
    return (
        <MainFrame
            className={`${styles.info} ${styles.order}`}
            title="주문목록"
            hideSiteName={true}
        >
            {orderItemList?.map((orderItem, index) => (
                <div
                    key={index}
                >
                    <ProductListItem
                        product={orderItem.product!}
                        mode="order"
                        onClick={() => clickHandler(orderItem)}
                    />
                    <div className={styles.productCount}   onClick={() => clickHandler(orderItem)}>
                        <div>수량 :</div>
                        {orderItem.sizes?.map((size, index) => (
                            <div key={index}>
                                {size.size?.toUpperCase()} / {size.count}
                                {index !== orderItem.sizes.length - 1 && ' ,'}
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </MainFrame>
    )
}

export default OrderItemList
