import {
    AddressDto,
    createAddressSaveDto,
    mapResponseToSave,
} from '@Types/address'
import { getModalInstance } from '@Component/common/Modal/ModalProvider'
import { useEffect, useState } from 'react'

import MainFrame, { ButtonOption } from '@Component/layout/MainFrame'
import styles from '@Css/form.module.scss'
import { requestOptions } from '@Component/domains/address/Editor/form'
import CheckBox from '@Component/common/Input/CheckBox'
import Button from '@Component/common/Input/Button'
import { getUserInfo } from '@Api/user/account'
import SearchAddress from '@Component/domains/address/Search/SearchAddress'
import { isValid, setPhone } from '@Util/formUtils'
import { addressValid } from '@Component/domains/address/Editor/valid'
import { saveValid } from '@Page/user/account/UserJoinPage/valid'
import { saveAddressApi } from '@Api/user/Address'
import FormField, { FormFieldProps } from '@Component/layout/FormField'

const AddressEditor = (props: { address?: AddressDto.Response }) => {
    const modal = getModalInstance()
    const [saveDto, setSaveDto] = useState<AddressDto.Save>(
        createAddressSaveDto()
    )

    const fetchData = async () => {
        const userInfo = await getUserInfo()
        if (userInfo) {
            const updatedDto = {
                ...saveDto,
                recipientName: userInfo.userName,
                recipientContact: userInfo.phoneNumber,
            }
            setSaveDto(updatedDto)
        }
    }

    // 데이터 유효성 검사
    const checkData = async () => {
        await isValid(saveDto, addressValid, false)
    }

    useEffect(() => {
        if (props.address) {
            const updatedSaveDto = mapResponseToSave(props.address)
            setSaveDto(updatedSaveDto)
        } else {
            fetchData()
        }
    }, [props.address])

    useEffect(() => {
        checkData()
    }, [saveDto])

    const formFields: FormFieldProps[] = [
        {
            label: '배송지 별칭',
            placeholder: 'ADDRESS NICKNAME',
            name: 'addressNickName',
            inputType: 'text',
            rightLabel: (
                <CheckBox
                    label={'기본배송지'}
                    data={saveDto}
                    setData={setSaveDto}
                    name={'mainAddress'}
                />
            ),
        },
        {
            label: '배송지 주소',
            placeholder: 'ADDRESS',
            name: 'address',
            inputType: 'text',
            rightLabel: (
                <Button
                    name={'주소검색'}
                    onClick={() => {
                        modal?.popup(
                            <SearchAddress
                                data={saveDto}
                                setData={setSaveDto}
                                name={'address'}
                            />
                        )
                    }}
                />
            ),
            disable: true,
        },
        {
            placeholder: 'ADDRESS DETAIL',
            name: 'addressDetail',
            inputType: 'text',
        },
        {
            label: '수령인 이름',
            placeholder: 'RECIPIENT NAME',
            name: 'recipientName',
            inputType: 'text',
        },
        {
            label: '수령인 연락처',
            placeholder: 'RECIPIENT CONTACT',
            name: 'recipientContact',
            inputType: 'text',
            onChange: (event) => setPhone(event),
        },
        {
            label: '배송요청사항',
            name: 'deliveryRequest',
            placeholder: '-요청사항을 선택해주세요-',
            inputType: 'select',
            options: requestOptions.map((option) => ({
                label: option,
                value: option,
            })),
            onChange: (event) => {
                const { name, value } = event.target
                const detailField = document.getElementById(
                    `${name}Detail`
                ) as HTMLElement

                if (value != '직접입력') {
                    detailField.style.display = 'none'
                    setSaveDto({
                        ...saveDto,
                        deliveryRequestDetail: value,
                    })
                } else {
                    detailField.style.display = 'block'
                    setSaveDto({
                        ...saveDto,
                        deliveryRequestDetail: '',
                    })
                }
            },
        },
        {
            placeholder: 'DELIVERY REQUEST',
            name: 'deliveryRequestDetail',
            inputType: 'text',
            displayNone: true,
        },
    ]

    const buttonOption: ButtonOption[] = [
        {
            name: props.address ? '수정' : '저장',
            onClick: async () => {
                if (await isValid(saveDto, addressValid, true)) {
                    const temp: any = { ...saveDto }
                    if (temp.deliveryRequest == '직접입력') {
                        temp.deliveryRequest = temp?.deliveryRequestDetail
                    }
                    await saveAddressApi(temp)
                    modal?.closeModal()
                }
            },
        },
    ]

    return (
        <MainFrame
            className={styles.form}
            title={'배송지 작성'}
            action={buttonOption}
            marginZero={true}
        >
            <FormField
                data={saveDto}
                setData={setSaveDto}
                formFieldList={formFields}
            />
        </MainFrame>
    )
}

export default AddressEditor
