import {getModalInstance} from "@Component/common/Modal/ModalProvider";

import {getOrderExcelListApi, orderWebHookService} from "@Api/user/order";
import * as PortOne from "@portone/browser-sdk/v2";
import {UserDto} from "@Types/user"
import {OrderDto} from "@Types/order";
import {CartDto} from "@Types/cart";
import React, {SetStateAction} from "react";
import {generateExcel} from "@Util/excelUtil";
import {decodeHTML} from "@Util/stringUtils";


export const transFormItem = (itemList: OrderDto.Item[]) => {
    const transformedList: OrderDto.TransformedOrder[] | CartDto.TransformedCart[] = []

    itemList.forEach((item: OrderDto.Item) => {
        if (
            !transformedList.some(
                (trans) =>
                    trans?.product?.productId === item?.product?.productId
            )
        ) {
            transformedList.push({
                product: item?.product!,
                sizes: [],
            })
        }

        transformedList
            .find(
                (trans) =>
                    item?.product?.productId === trans?.product?.productId
            )
            ?.sizes.push({
            size: item.size,
            count: item.count,
        })
    })

    return transformedList
}
export const calculatePrice = (
    orderList?: OrderDto.TransformedOrder[],
    setTotalPrice?: React.Dispatch<SetStateAction<number>>,
    setTotalDeliveryFee?: React.Dispatch<SetStateAction<number>>,
    selectDto?: Record<string, boolean> // selectDto는 선택사항
) => {
    if (!setTotalPrice || !setTotalDeliveryFee || !orderList) {
        return;
    }

    let calculatedTotalPrice = 0;
    let minDeliveryFee = Number.MAX_VALUE;
    let minDeliveryFeeThreshold = Number.MAX_VALUE;

    const hasSelectedProducts = selectDto
        ? orderList.some(order => selectDto[order.product.productId!]) // selectDto가 있을 때
        : orderList.length > 0; // selectDto가 없으면 전체 상품을 계산

    if (hasSelectedProducts) {
        orderList.forEach((order) => {
            // selectDto가 없으면 전체 상품 계산, 있으면 선택된 상품만 계산
            if (!selectDto || selectDto[order.product.productId!]) {
                // 각 아이템의 개수만큼 가격을 곱함
                order.sizes.forEach((item) => {
                    const productPrice = parseInt(order.product.price || "0", 10);
                    const itemCount = item.count || 0; // 개수 (count)
                    calculatedTotalPrice += productPrice * itemCount; // 가격 * 개수

                    const deliveryFee = parseInt(order.product.deliveryFee || "0", 10);
                    const deliveryFeeThreshold = parseInt(order.product.deliveryFeeThreshold || "0", 10);

                    if (deliveryFeeThreshold < minDeliveryFeeThreshold) {
                        minDeliveryFeeThreshold = deliveryFeeThreshold;
                    }

                    if (deliveryFee < minDeliveryFee) {
                        minDeliveryFee = deliveryFee;
                    }
                });
            }
        });

        // 총 가격이 최소 배송비 조건을 넘으면 무료 배송
        if (calculatedTotalPrice >= minDeliveryFeeThreshold) {
            minDeliveryFee = 0;
        }

        setTotalPrice(calculatedTotalPrice);
        setTotalDeliveryFee(minDeliveryFee);
    } else {
        // 선택된 상품이 없을 경우 초기화
        setTotalPrice(0);
        setTotalDeliveryFee(0);
    }
};


export const onClickPayment = async (
    user: UserDto.Info,
    orderDto: OrderDto.Response
) => {
    const modal = getModalInstance()
    try {
        const response = await PortOne.requestPayment({
            // Store ID 설정
            storeId: process.env.PORTONE_STORE_ID!,
            // 채널 키 설정
            channelKey: process.env.PAYMENT_KEY!,
            paymentId: orderDto?.orderCode!,
            // 주문목록
            orderName: orderDto?.orderName!,
            // 주문가격
            totalAmount: orderDto?.paymentAmount!,

            currency: 'CURRENCY_KRW',
            payMethod: 'CARD',

            // 유저정보
            customer: {
                email: user.email!,
                phoneNumber: user.phoneNumber!,
                fullName: user.userName!,
            },

            redirectUrl: `${process.env.REDIRECT_URL}`,
        })

        if (response && response.code != null) {
            modal?.alert(() => {
            }, '알림', '결제가 취소되었습니다.')
            return;
        }

        await orderWebHookService(response!)
    } catch (e) {
        if (e instanceof Error && e.message.includes('이미 결제된 id입니다.')) {
            modal?.alert(
                () => {
                    window.location.href = '/mypage/orders'
                },
                '알림',
                '이미 결제된 주문입니다.'
            )
        }
    }
}

export const excelListDownload = async (search: OrderDto.Search) => {
    const modal = getModalInstance();
    const orderList = await getOrderExcelListApi(search);

    if (!orderList || orderList.length === 0) {
        modal?.alert(() => {
        }, '알림', '조회 내역이 없습니다.');
        return;
    }

    let data: any[] = [];

    orderList.forEach((order) => {
        data = [...data, ...generateExcelData(order)]
    });

    await generateExcel(data, '주문정보', '주문목록');
};


export const excelDownload = async (orderDto?: OrderDto.Response) => {
    const modal = getModalInstance();

    const data: any[] = generateExcelData(orderDto || {})

    await generateExcel(data, '주문정보', orderDto?.orderCode + '주문정보');
}

const generateExcelData = (orderDto: OrderDto.Response) => {
    const data: any[] = [];

    data.push(
        ['h_주문번호', 'h_수령인', 'h_수령인 전화번호', 'h_배송요청사항', 'h_주소'],
        [
            orderDto?.orderCode,
            orderDto?.recipientName,
            orderDto?.recipientContact,
            orderDto?.deliveryRequest || '',
            decodeHTML(`${orderDto?.address || ''} ${orderDto?.addressDetail || ''}`),
        ]
    );

    // 상품 정보 헤더 추가 (주문 항목이 있을 경우만)
    if (orderDto?.orderItemList?.length) {
        data.push(['h_상품명', 'h_상품코드', 'h_사이즈', 'h_개수', 'h_상품페이지 주소']);
        orderDto?.orderItemList.forEach((item, index) => {
            data.push([
                index == 0 && item.product?.name || '',
                index == 0 && item.product?.productCode || '',
                item.size || '',
                item.count?.toString() || '0',
                index == 0 ? (`https://brospo.co.kr/product/order/detail/${item?.product?.productId}`) : '',
            ]);
        });
    }

    // 구분선 추가
    data.push(['_hr_', '_hr_', '_hr_', '_hr_', '_hr_']);

    return data
}

