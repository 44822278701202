import { userFindApi, userLoginApi } from '@Api/user/account'
import ImageTag from '@Component/common/Image/ImageTag'
import FormField from '@Component/layout/FormField'
import MainFrame from '@Component/layout/MainFrame'
import { preventLoggedInAccess } from '@Service/user/UserService'
import { UserDto, createUserFindDto, createUserLoginDto } from '@Types/user'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { formList } from '../UserLoginPage/form'
import styles from '@Css/form.module.scss'
import { TabBar } from '@Component/layout/TabBar'
import { findForm } from './form'
import Modal from '@Component/common/Modal/Modal'
import { getModalInstance } from '@Component/common/Modal/ModalProvider'
import { findValid } from './valid'
import { isValid } from '@Util/formUtils'

const UserFindPage = () => {
    const [findDto, setFindDto] = useState<UserDto.Find>(createUserFindDto)
    const submit = async () => {
        if (await isValid(findDto, findValid, true)) {
            userFindApi(findDto)
        }
    }

    useEffect(() => {
        preventLoggedInAccess()
    }, [])

    const userFindTabOptions = [
        { label: '아이디 찾기', link: '/user/find/id' },
        { label: '비밀번호 재설정', link: '/user/find/pwd' },
    ]
    return (
        <MainFrame className={styles.form} maxWidth={700}>
            <TabBar options={userFindTabOptions} />
            <FormField
                formFieldList={findForm}
                data={findDto}
                setData={setFindDto}
                onKeyDown={submit}
                validOption={findValid}
            />
            <input
                type="button"
                value="아이디 찾기"
                onClick={submit}
                className={styles.grayButton}
            />
        </MainFrame>
    )
}

export default UserFindPage
