import React, {useEffect, useState} from 'react'
import Item from '@Component/layout/ListField/Item'
import ImageTag from '@Component/common/Image/ImageTag'
import {getPlainText, privacyName} from '@Util/stringUtils'
import {getModalInstance} from '@Component/common/Modal/ModalProvider'
import ListTemplate, {RowOption,} from '@Component/layout/ListField/ListTemplate'
import Header, {HeaderOption} from '@Component/layout/ListField/Header'
import Footer, {FooterOption} from '@Component/layout/ListField/Footer'
import {PageBar} from '@Component/common/PageBar'
import {BoardCategory, BoardDto, createBoardSearchDto} from '@Types/board'
import {useNavigate} from 'react-router-dom'
import {getUserInfo} from '@Api/user/account'
import {ReviewEditor} from '@Component/domains/board/Edit/ReviewEditor'
import {getBoardListApi} from '@Api/user/board'
import styles from './index.module.scss'
import {formatDate} from '@Util/dateUtile'
import {ReviewView} from '@Component/domains/board/View/ReviewView'
import ReviewRating from '@Component/domains/board/ReviewRating'
import {UserDto} from '@Types/user'
import SearchField, {ActionField} from '@Component/layout/SearchField'
import {boardSearch} from './searchFeild'
import {getAdminBoardListApi} from '@Api/admin/board'
import {AnswerEditor} from '../Edit/AnswerEditor'
import {useListView} from "../../../../context/board/ListViewContext";
import {ReviewGridList} from "@Component/domains/board/List/ReviewGridList";

interface Props {
    productId?: string
    isAdmin?: boolean
    moreView?: boolean
    write?: boolean
    noAnswer?: boolean
    userId?: string | number
}

const ReviewList: React.FC<Props> = ({
                                         productId,
                                         isAdmin,
                                         moreView,
                                         write,
                                         noAnswer,
                                         userId,

                                     }) => {
    const navigate = useNavigate()
    const modal = getModalInstance()
    const [search, setSearch] = useState<BoardDto.Search>(
        createBoardSearchDto(BoardCategory.Review)
    )
    const [boardList, setBoardList] = useState<BoardDto.Response[]>([])
    const [page, setPage] = useState<number>(1)
    const [totalCount, setTotalCount] = useState<number>(0)
    const [rowSize, setRowSize] = useState<number>(6)
    const [userInfo, setUserInfo] = useState<UserDto.Info>()
    const {viewMode} = useListView();


    const headerOptions: HeaderOption[] = [
        {name: '제목', rowCount: 2, width: '60%'},
        {name: '작성자', rowCount: 1, width: '20%'},
        {name: '작성일', rowCount: 1, hidden: true, width: '20%'},
    ]

    const rowOptions: RowOption[] =
        boardList.length != 0
            ? boardList.map((board, index) => ({
                rowList: [
                    <Item
                        key={`img-${index}`}
                        content={
                            <div>
                                <div className={styles.thumbnail}>
                                    <ImageTag
                                        src={`${process.env
                                            .BOARD_IMAGE_BASE_URL!}${
                                            board.boardId
                                        }/0.png`}
                                        draggable="false"
                                        alt={'리뷰 메인이미지' + index}
                                        fallbackSrc={`${process.env
                                            .PRODUCT_IMAGE_BASE_URL!}${
                                            board.product?.productId
                                        }/THUMBNAIL/0.png`}
                                    />
                                </div>
                                <div className={styles.description}>
                                    <div>{board.title}</div>
                                    <p>
                                        <ReviewRating
                                            data={board}
                                            name={'reviewRating'}
                                            size={'var(--font-size-0-8)'}
                                        />
                                    </p>
                                    <p
                                        className={styles.content}
                                        dangerouslySetInnerHTML={{
                                            __html: getPlainText(
                                                board.content
                                            ),
                                        }}
                                    ></p>
                                </div>
                            </div>
                        }
                        colSpan={2}
                    />,
                    <Item
                        content={privacyName(board?.user?.userName || '')}
                        colSpan={1}
                    />,
                    <Item
                        content={formatDate(board.saveDate, 'yyyy/MM/dd')}
                        colSpan={1}
                        hidden={true}
                    />,
                ],
                onClick: () => {
                    modal?.popup(
                        isAdmin ? (
                            <AnswerEditor boardId={board.boardId}/>
                        ) : (
                            <ReviewView boardId={board.boardId}/>
                        ),
                        '800px',
                        () => {
                            fetchData()
                        }
                    )
                },
            }))
            : [
                {
                    rowList: [
                        <Item
                            key="no-reviews"
                            content={
                                <div className={'empty-view'}>
                                    리뷰가 존재하지 않습니다.
                                </div>
                            }
                            colSpan={4}
                        />,
                    ],
                },
            ]

    const footerOption: FooterOption[] = [
        ...(moreView
            ? [
                {
                    name: '모두보기',
                    onClick: () => navigate('/board/review'),
                },
            ]
            : []),
        ...(write
            ? [
                {
                    name: '작성하기',
                    onClick: () => handleWrite(),
                },
            ]
            : []),
    ]

    const actionFieldList: ActionField[] = [
        {
            label: '검색',
            onClick: () => {
                fetchData()
            },
        },
    ]

    const handleWrite = async () => {
        if (await getUserInfo(true)) {
            modal?.popup(
                <ReviewEditor productId={productId!}/>,
                '800px',
                () => {
                    fetchData()
                }
            )
        } else {
            modal?.confirm(
                () => {
                    navigate('/user/login')
                },
                '알림',
                '작성을 위해 로그인이 필요합니다.',
                '로그인 하시겠습니까?'
            )
        }
    }

    const fetchData = async () => {
        const temp = search
        temp.page = page
        temp.rowSize = rowSize
        temp.productId = productId
        if (noAnswer) {
            temp.answer = true
        }
        if (userId) {
            temp.userId = userId
        }
        const result = isAdmin
            ? await getAdminBoardListApi(temp)
            : await getBoardListApi(temp)
        if (page != 1 && !result?.content) {
            setPage(page - 1)
        }
        setBoardList(result?.content || [])
        setTotalCount(result?.totalElements || 0)
    }

    useEffect(() => {
        fetchData()
    }, [productId, page, userId, viewMode])

    useEffect(() => {
        setRowSize(viewMode == 'list' ? 6 : 10)
    }, [viewMode]);
    return (
        <>
            {isAdmin && !noAnswer && (
                <SearchField
                    searchFieldList={boardSearch()}
                    actionFieldList={actionFieldList}
                    data={search}
                    setData={setSearch}
                />
            )}
            {viewMode == 'list' ?
                <ListTemplate
                    extraClass={styles.review}
                    header={<Header options={headerOptions}/>}
                    row={rowOptions}
                    footer={<Footer options={footerOption}/>}
                /> : <><ReviewGridList boardList={boardList}/>
                    <ListTemplate
                        extraClass={styles.review}
                        header={<></>}
                        row={[]}
                        footer={<Footer options={footerOption}/>}
                    />
                </>}
            <PageBar
                rowSize={rowSize}
                totalCount={totalCount}
                page={page}
                setPage={setPage}
            />
        </>
    )
}

export default ReviewList
