import {useParams, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";

import {PageBar} from "@Component/common/PageBar";
import {PrimaryCategoryBar} from "@Component/domains/product/CategoryBar/PrimaryCategoryBar";
import {SecondaryCategoryBar} from "@Component/domains/product/CategoryBar/SecondaryCategoryBar";
import {ProductSortSelector} from "@Component/domains/product/ProductList/ProductSortSelector";
import {findCategoryById} from "@Types/category/categoryDto";
import MainFrame from "@Component/layout/MainFrame";
import styles from "@Css/product/productList.module.scss";
import {ProductList} from "@Component/domains/product/ProductList";
import NotFound from "@Component/layout/NotFound/NotFound";
import {ProductDto} from "@Types/product";
import {getProductListApi} from "@Api/user/product";


const ProductListPage = (): JSX.Element => {
     const [productList, setProductList] = useState<ProductDto.Response[] | null>(null)
     const [totalCount, setTotalCount] = useState<number>(0)
     const [page, setPage] = useState<number>(1)
     const [sortMode, setSortMode] = useState<string>('1')
     const rowSize = 20
     const {primaryCategory, secondaryCategory} = useParams<{
         primaryCategory: string
         secondaryCategory: string
     }>()
     const [searchParams] = useSearchParams()
     const title = searchParams.get('title')

     async function fetchData() {
         const searchDto: ProductDto.Search = {
             page: page || 1,
             rowSize: rowSize,
             productName: title || '',
             sortMode: sortMode || '1'
         }

         // 카테고리 선택안된경우
         if (!primaryCategory && !secondaryCategory) {
         }
         // 1차카테고리만 선택된경우
         else if (
             (primaryCategory && !secondaryCategory) ||
             (primaryCategory && secondaryCategory == 'all')
         ) {
             searchDto.primaryCategory = primaryCategory
             // 2차카테고리 선택경우
         } else if (primaryCategory && secondaryCategory) {
             const category = await findCategoryById(
                 primaryCategory,
                 secondaryCategory
             )
             if (category != null) {
                 searchDto.categoryId = category.categoryId!
             } else {
                 if (primaryCategory == '비공개') {
                     searchDto.categoryId = '1'
                 } else {
                     // 카테고리 목록에 없는경우 상품은 존재 x
                     setProductList(null)
                     return
                 }
             }
         }
         const responses = await getProductListApi(searchDto);

         setProductList(responses?.content || [])
         setTotalCount(Number(responses?.totalElements) || 0)
     }

     useEffect(() => {
         fetchData()

     }, [primaryCategory, secondaryCategory, page, sortMode, title])

    return (
        <MainFrame className={styles.productList} title={<React.Fragment>
            {primaryCategory}
            {(secondaryCategory && secondaryCategory != 'all') ? (
                <i className="icon-right-open"></i>
            ) : <React.Fragment><i className="icon-right-open"></i>전체상품</React.Fragment>}
            {secondaryCategory != 'all' ? secondaryCategory : ''}
        </React.Fragment>} maxWidth={1400}
                   documentTitle={secondaryCategory == 'all' ? primaryCategory : secondaryCategory || primaryCategory || '전체상품'}>
            <div className={styles.productCategory}>
                <PrimaryCategoryBar/>
                <SecondaryCategoryBar/>
            </div>
            <div className={styles.productListInfo}>
                <div className={styles.productItemCount}>
                    <p>{totalCount}</p>
                    <span>item</span>
                </div>
                <ProductSortSelector setSortMode={setSortMode}/>
            </div>

            {productList?.length != 0 ? (
                <ProductList productList={productList}/>
            ) : (
                <NotFound/>
            )}

            <PageBar page={page} setPage={setPage} rowSize={rowSize} totalCount={totalCount}/>
        </MainFrame>
    )
}

export default ProductListPage
