import React, { useEffect, useState } from "react";
import { BoardCategory, BoardDto, createBoardSaveDto, mapResponseToSave } from "@Types/board";
import FormField, { FormFieldProps } from "@Component/layout/FormField";
import styles from "@Css/form.module.scss";
import ReviewRating from "@Component/domains/board/ReviewRating";
import MainFrame, { ButtonOption } from "@Component/layout/MainFrame";
import { isValid } from "@Util/formUtils";
import { noticeValid, reviewValid } from "@Component/domains/board/Edit/valid";
import { saveBoard } from "@Service/board";
import { getBoardApi } from "@Api/user/board";
import { getFileMap } from "@Util/imageUtils";
import ImageDrop from "@Component/common/Image/ImageDrop/ImageDrop";
import { Editor } from "@Component/common/Input/Editor/Editor";
import { getAdminBoardApi } from "@Api/admin/board";
import FileUpload from "@Component/common/Input/FileUpload";

export const NoticeEditor = (props: { boardId?: string }) => {

    const [saveDto, setSaveDto] = useState<BoardDto.Save>(createBoardSaveDto(BoardCategory.Notice))
    const [defaultData, setDefaultData] = useState<string>()
    const [fileMap, setFileMap] = useState<Map<number, File>>(new Map())


    const noticeForm: FormFieldProps[] = [
        {
            label: '공지 제목',
            placeholder: 'NOTICE TITLE',
            inputType: 'text',
            name: 'title'
        },
    ];


    const actionButton: ButtonOption[] = [
        {
            name: props.boardId ? '수정' : '저장',
            onClick: async () => {
                console.log(fileMap)
                if (await isValid(saveDto, noticeValid, true)) {
                    saveBoard(saveDto, new Map(), fileMap, true)

                }
            }
        }
    ]
    const fetchData = async (boardId?: string) => {
        if (props.boardId) {
            const response = await getAdminBoardApi(props.boardId)
            if (response) {
                setSaveDto(mapResponseToSave(response))
                setFileMap(await getFileMap(response?.fileUrl || []));
                setDefaultData(response.content)
            }
        }
    }

    useEffect(() => {
        fetchData(props.boardId)
    }, [props.boardId])

    return (
        <MainFrame className={styles.form} maxWidth={900} title={props.boardId ? '공지 수정' : '공지 작성'} marginZero={true} width={'96%'}
            action={actionButton}>
            <FormField formFieldList={noticeForm} data={saveDto} setData={setSaveDto} validOption={noticeValid} />
            <h3>파일 업로드</h3>
            <FileUpload fileMap={fileMap} setFileMap={setFileMap} />
            <h3>공지 내용</h3>
            <Editor name={'content'}
                data={saveDto}
                setData={setSaveDto}
                height={300}
                defaultData={defaultData}
            />
        </MainFrame>
    )
}
