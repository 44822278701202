import {userJoinApi, userLoginApi, userResetApi} from '@Api/user/account'
import ImageTag from '@Component/common/Image/ImageTag'
import FormField, { FormFieldProps } from '@Component/layout/FormField'
import MainFrame from '@Component/layout/MainFrame'
import {
    createAuthToken,
    preventLoggedInAccess,
} from '@Service/user/UserService'
import {
    UserDto,
    createUserFindDto,
    createUserLoginDto,
    createUserResetDto,
} from '@Types/user'
import { useState, useEffect } from 'react'

import styles from '@Css/form.module.scss'
import { TabBar } from '@Component/layout/TabBar'

import Button from '@Component/common/Input/Button'
import { isValid, setEmail } from '@Util/formUtils'
import { resetValid } from './valid'
import { resetForm } from './form'
import { getModalInstance } from '@Component/common/Modal/ModalProvider'

const UserResetPage = () => {
    const modal = getModalInstance()
    const [resetDto, setResetDto] = useState<UserDto.Reset>(createUserResetDto)
    const [createToken, setCreateToken] = useState<boolean>(true)

    const submit = async () => {
        if (await isValid(resetDto, resetValid, true)) {
            if (createToken) {
                modal?.alert(
                    () => {},
                    '알림',
                    '인증토큰을 발급/재발급 해주세요.'
                )
            } else {
                userResetApi(resetDto)
            }
        }
    }

    useEffect(() => {
        preventLoggedInAccess()
    }, [])

    const userFindTabOptions = [
        { label: '아이디 찾기', link: '/user/find/id' },
        { label: '비밀번호 재설정', link: '/user/find/pwd' },
    ]

    return (
        <MainFrame className={styles.form} maxWidth={700}>
            <TabBar options={userFindTabOptions} />
            <FormField
                formFieldList={resetForm}
                data={resetDto}
                setData={setResetDto}
                onKeyDown={submit}
                validOption={resetValid}
            />
            <label htmlFor="auth-token-form">
                <p>
                    인증번호 입력<span id="auth-token-message"></span>
                </p>
                <Button
                    name="인증번호받기"
                    onClick={() => {
                        createAuthToken(resetDto, createToken, setCreateToken)
                    }}
                />
            </label>
            <input
                type="text"
                placeholder="AUTH TOKEN"
                onChange={(event) => {
                    const { value } = event.target as HTMLInputElement
                    setResetDto((prev) => ({
                        ...prev,
                        authToken: value,
                    })) // authToken 업데이트
                }}
            />
            <input
                type="button"
                value="비밀번호 재설정"
                onClick={submit}
                className={styles.grayButton}
            />
        </MainFrame>
    )
}

export default UserResetPage
