import MainFrame from "@Component/layout/MainFrame";
import {CmmDto} from "@Types/cmm";
import styles from './index.module.scss'
import {useEffect, useState} from "react";
import {ProductDto} from "@Types/product";

export const ProductSummery: React.FC<{ summery?: CmmDto.Summery, type?: string }>
    = ({summery, type}) => {
    const [productList, setProductList] = useState<ProductDto.Response[]>()

    useEffect(() => {
        if (type == 'day') {
            setProductList(summery?.dailyProduct)
        } else {
            setProductList(summery?.monthProduct)
        }

    }, [summery, type]);

    return <MainFrame title={type == 'day' ? '일간 인기상품' : '월간 인기상품'} marginZero={true}>
        <div className={`${styles.productItem } ${styles.title}`}>
            <span>상품명</span>
            <span>일 조회수</span>
            <span>월 조회수</span>
            <span>총 조회수</span>
            <span>후기</span>
        </div>
        {productList?.map((product, index) => (
            <div className={styles.productItem} key={index}>
                <span>{product.name}</span>
                <span>{product.dailyCount} 회</span>
                <span>{product.monthlyCount} 회</span>
                <span>{product.totalCount} 회</span>
                <span>{product.reviewCount} 건</span>
            </div>))}
    </MainFrame>
}