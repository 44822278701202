import React, { useEffect } from 'react';
import { getModalInstance } from "@Component/common/Modal/ModalProvider";
import { useNavigate } from "react-router-dom";

const ActionHandler: React.FC = () => {

    const navigate = useNavigate()

    const getCookie = (name: string): string | null => {
        const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
        return match ? decodeURIComponent(match[2]) : null;
    };

    // 쿠키 삭제 함수
    const deleteCookie = (name: string) => {
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    };

    // 페이지 이동 함수
    const redirectTo = (url: string) => {
        window.location.href = url;
    };

    useEffect(() => {
        const alertMessage = getCookie('alertMessage');
        if (alertMessage) {
            const modal = getModalInstance()
            // 메시지가 있으면 alert로 출력
            modal?.alert(() => { }, "알림", alertMessage);
            // 메시지를 출력한 후 쿠키 삭제
        }
        const action = getCookie('actionType');
        if (action === 'referrer') {
            // 3. 로컬 스토리지에서 referrer 가져오기
            const referrer = localStorage.getItem('referrer');
            if (referrer) {
                // referrer가 있으면 해당 링크로 이동
                navigate(referrer.replace("http://localhost:3000", '').replace("https://brospo.co.kr", ''));
            }
        }
        deleteCookie('alertMessage');
        deleteCookie('actionType');
    }, []); // 빈 배열을 두어 컴포넌트가 마운트될 때 한 번만 실행

    return null; // UI가 없는 컴포넌트
};

export default ActionHandler;
