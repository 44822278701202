import React from 'react'

import {About} from '@Component/domains/cmm/Document/About/About'
import MainFrame from "@Component/layout/MainFrame";

function AboutPage() {

    return (
        <MainFrame title={'회사소개'} maxWidth={900} documentTitle={'소개'}>
            <About />
        </MainFrame>
    )
}

export default AboutPage
