import {getModalInstance} from "@Component/common/Modal/ModalProvider";
import styles from './index.module.scss'
import Button from "@Component/common/Input/Button";
import {CartDto} from "@Types/cart";
import React, {SetStateAction, useEffect, useState} from "react";
import {deleteCartApi} from "@Api/user/cart";
import {insertOrderApi} from "@Api/user/order";
import {calculatePrice} from "@Service/order/OrderService";
import {useNavigate} from "react-router-dom";

interface Props {
    cartList?: CartDto.TransformedCart[]
    selectDto: any
    setSelectDto: React.Dispatch<SetStateAction<any>>
    setRefresh: React.Dispatch<SetStateAction<boolean>>
}

export const CartPrice: React.FC<Props> = ({cartList, selectDto, setSelectDto, setRefresh}) => {
    const navigate = useNavigate()
    const [totalPrice, setTotalPrice] = useState<number>(0);
    const [totalDeliveryFee, setTotalDeliveryFee] = useState<number>(0);
    const modal = getModalInstance();
    useEffect(() => {
        calculatePrice(cartList, setTotalPrice, setTotalDeliveryFee, selectDto)
    }, [selectDto, cartList]);

    const selectCartRemove = async () => {
        const itemsToDelete = cartList?.filter(cart => selectDto[cart.product.productId!] === true);
        const tempDto: CartDto.Delete[] = itemsToDelete?.map(cart => ({
            productId: cart.product.productId
        })) || [];

        if (tempDto.length > 0) {
            modal?.confirm(async () => {
                await deleteCartApi(tempDto);
                setSelectDto({});
                setRefresh(true);
            }, '확인', '선택된 상품들을 삭제하시겠습니까?');
        } else {
            modal?.alert(() => {
            }, '알림', '선택된 상품이 없습니다.');
        }
    };

    const handleSelectAll = () => {
        const allSelected = cartList?.every(cart => selectDto[cart.product.productId!] === true);
        const updatedSelectDto = {...selectDto};
        cartList?.forEach(cart => {
            updatedSelectDto[cart.product.productId!] = !allSelected;
        });
        setSelectDto(updatedSelectDto);
    };

    const handleCheckOut = async () => {
        // 선택된 상품들의 sizes 배열을 기반으로 OrderDto.Item[] 구조로 변환
        const orderList = cartList?.flatMap(cart =>
            selectDto[cart.product.productId!] ? cart.sizes : []
        );

        if (orderList && orderList.length > 0) {
            if (await insertOrderApi(orderList))
                navigate('/order/checkout')
        } else {
            modal?.alert(() => {
            }, '알림', '선택된 상품이 없습니다.');
        }
    };

    return (
        <div className={styles.price}>
            <div className={styles.info}>
                <div className={styles.buttonPlace}>
                    <Button
                        name="전체선택"
                        onClick={handleSelectAll}
                    />
                    <Button
                        name="선택상품제거"
                        onClick={selectCartRemove}
                    />
                    <Button
                        black={true}
                        name="주문하기"
                        onClick={handleCheckOut}
                    />
                </div>
                <div>
                    총 상품가격 <span id="order-cart-price">{totalPrice.toLocaleString()} 원</span>
                </div>
                <div>
                    배송비 <span id="order-cart-delivery-fee">+ {totalDeliveryFee.toLocaleString()} 원</span>
                </div>
                <div className="total-price">
                    총 결제금액
                    <span id="order-cart-total-price">{(totalPrice + totalDeliveryFee).toLocaleString()} 원</span>
                </div>
            </div>
        </div>
    );
};

export default CartPrice;
