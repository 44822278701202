import React, { useEffect, useRef } from 'react'
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { modelConfigs, canvasConfig } from './3dConfig'
import './3dCanvas.css'
import Button from '../Input/Button'

interface CanvasViewProps {
    imageUrl: string
    model: string
}

const CanvasView: React.FC<CanvasViewProps> = ({
    imageUrl,
    model: modelType,
}) => {
    const mountRef = useRef<HTMLDivElement>(null)
    const sceneRef = useRef<THREE.Scene | null>(null)
    const cameraRef = useRef<THREE.PerspectiveCamera | null>(null)
    const rendererRef = useRef<THREE.WebGLRenderer | null>(null)
    const controlsRef = useRef<OrbitControls | null>(null)
    const modelRef = useRef<THREE.Group | null>(null)
    const defaultPositionRef = useRef<THREE.Vector3>(new THREE.Vector3())

    const loadTexture = (url: string): THREE.Texture => {
        const textureLoader = new THREE.TextureLoader()
        const texture = textureLoader.load(url, () => {
            // 텍스처가 로드된 후 해상도를 조정할 수 있습니다
            texture.minFilter = THREE.LinearFilter
            texture.magFilter = THREE.LinearFilter
            texture.anisotropy = 4 // 더 높은 anisotropy 설정
        })
        texture.wrapS = THREE.RepeatWrapping
        texture.wrapT = THREE.RepeatWrapping
        texture.colorSpace = THREE.SRGBColorSpace
        return texture
    }

    const setupScene = (): {
        scene: THREE.Scene
        camera: THREE.PerspectiveCamera
        renderer: THREE.WebGLRenderer
    } => {
        const scene = new THREE.Scene()
        const camera = new THREE.PerspectiveCamera(
            canvasConfig.camera.fov,
            canvasConfig.camera.aspect,
            canvasConfig.camera.near,
            canvasConfig.camera.far
        )
        const renderer = new THREE.WebGLRenderer({ antialias: true })
        renderer.setPixelRatio(window.devicePixelRatio)
        renderer.setSize(
            mountRef.current!.clientWidth,
            mountRef.current!.clientHeight
        )
        renderer.setClearColor(canvasConfig.renderer.clearColor)
        renderer.shadowMap.enabled = canvasConfig.renderer.shadowMapEnabled
        renderer.shadowMap.type = THREE.PCFSoftShadowMap
        mountRef.current!.appendChild(renderer.domElement)

        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')!
        canvas.width = 1
        canvas.height = 1000
        const gradient = ctx.createLinearGradient(0, 0, 0, canvas.height)
        gradient.addColorStop(0, '#d3d3d3')
        gradient.addColorStop(1, '#404040')
        ctx.fillStyle = gradient
        ctx.fillRect(0, 0, 1, 1000)
        const texture = new THREE.CanvasTexture(canvas)
        scene.background = texture

        sceneRef.current = scene
        cameraRef.current = camera
        rendererRef.current = renderer

        return { scene, camera, renderer }
    }

    const setupLights = (
        scene: THREE.Scene,
        camera: THREE.PerspectiveCamera
    ) => {
        const cameraGroup = new THREE.Group()
        scene.add(cameraGroup)
        cameraGroup.add(camera)

        canvasConfig.lights.forEach((lightConfig) => {
            let light: THREE.Light | null = null
            if (lightConfig.type === 'directional') {
                light = new THREE.DirectionalLight(
                    lightConfig.color,
                    lightConfig.intensity
                )
                if (lightConfig.position) {
                    light.position.copy(lightConfig.position).normalize()
                }
            } else if (lightConfig.type === 'ambient') {
                light = new THREE.AmbientLight(
                    lightConfig.color,
                    lightConfig.intensity
                )
            }

            if (light) {
                cameraGroup.add(light)
            }
        })
    }

    const loadModel = (scene: THREE.Scene, camera: THREE.PerspectiveCamera) => {
        const config = modelConfigs[modelType]
        if (!config) {
            console.error(
                `Model type "${modelType}" not found in modelConfigs.`
            )
            return
        }

        const { url, position } = config
        defaultPositionRef.current.copy(position)
        camera.position.copy(position)

        const loader = new GLTFLoader()
        loader.load(
            url,
            (gltf) => {
                const loadedModel = gltf.scene
                scene.add(loadedModel)
                modelRef.current = loadedModel

                const box = new THREE.Box3().setFromObject(loadedModel)
                const center = new THREE.Vector3()
                box.getCenter(center)
                loadedModel.position.sub(center)

                const texture = loadTexture(imageUrl)
                loadedModel.traverse((child) => {
                    if (child instanceof THREE.Mesh) {
                        const material =
                            child.material as THREE.MeshStandardMaterial
                        if (material.map) material.map.dispose()
                        material.map = texture
                        material.roughness = canvasConfig.texture.roughness
                        material.metalness = canvasConfig.texture.metalness
                        material.needsUpdate = true
                    }
                })
            },
            undefined,
            (error) => console.error('Model loading error:', error)
        )
    }

    const rotateHandler = () => {
        if (modelRef.current && controlsRef.current) {
            controlsRef.current.reset()
            modelRef.current.rotation.y += Math.PI / 2
        }
    }

    const handleResize = () => {
        if (cameraRef.current && rendererRef.current && mountRef.current) {
            const width = mountRef.current.clientWidth
            const height = mountRef.current.clientHeight
            rendererRef.current.setSize(width, height)
            cameraRef.current.updateProjectionMatrix()
        }
    }

    useEffect(() => {
        if (!imageUrl || !modelType || !mountRef.current) return

        const { scene, camera, renderer } = setupScene()

        setupLights(scene, camera)
        loadModel(scene, camera)

        const controls = new OrbitControls(camera, renderer.domElement)
        controls.enableDamping = canvasConfig?.controls.enableDamping
        controls.dampingFactor = canvasConfig?.controls.dampingFactor
        controls.enableZoom = canvasConfig?.controls.enableZoom
        controls.enablePan = canvasConfig.controls.enablePan
        controls.rotateSpeed = canvasConfig.controls.rotateSpeed
        controlsRef.current = controls

        const animate = () => {
            controls.update()
            renderer.render(scene, camera)
            requestAnimationFrame(animate)
        }
        animate()

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)

            if (controls) controls.dispose()
            if (renderer) renderer.dispose()

            if (modelRef.current) {
                modelRef.current.traverse((child) => {
                    if (child instanceof THREE.Mesh) {
                        child.geometry.dispose()
                        if (Array.isArray(child.material)) {
                            child.material.forEach((material) =>
                                material.dispose()
                            )
                        } else {
                            child.material.dispose()
                        }
                    }
                })
                scene.remove(modelRef.current)
            }
            sceneRef.current = null
            cameraRef.current = null
            rendererRef.current = null
            controlsRef.current = null
            modelRef.current = null
        }
    }, [imageUrl, modelType])

    return (
        <React.Fragment>
            <div ref={mountRef} className="view-canvas">
                <Button
                    name="↺"
                    extraClass="bg-white-button"
                    onClick={rotateHandler}
                />
            </div>
            <span style={{ fontSize: 'var(--font-size-0-9)', color: 'blue' }}>
                3D 이미지와 실제상품은 차이가 있을 수 있습니다.
            </span>
        </React.Fragment>
    )
}

export default CanvasView
