import { ModalProvider } from '@Component/common/Modal/ModalProvider'

import { BrowserRouter, Route, Routes } from 'react-router-dom'

import IndexPage from '@Page/user/cmm/IndexPage/IndexPage'
import AboutPage from '@Page/user/cmm/AboutPage/AboutPage'
import PrivacyPage from '@Page/user/cmm/PrivacyPage'
import AgreementPage from '@Page/user/cmm/AgreementPage'
import UserLoginPage from '@Page/user/account/UserLoginPage'
import UserJoinPage from '@Page/user/account/UserJoinPage'
import { MyPage } from '@Page/user/mypage'
import ProductListPage from '@Page/user/product/ProcutListPage'
import ProductDetail from '@Page/user/product/ProductDetailPage'
import OrderPage from '@Page/user/order/orderPage'

import Footer from '@Component/layout/Footer'
import { Loading } from '@Component/layout/Loading'
import NotFound from '@Component/layout/NotFound/NotFound'
import SwaggerViewer from '@Component/domains/cmm/Swagger/SwaggerViewer'
import { AdminOrderPage } from '@Page/admin/order/AdminOrderPage'

import AdminUnprocessedPage from '@Page/admin/board/Unprocessed'

import React from 'react'
import InquiryPage from '@Page/user/board/InquiryPage'
import ReviewPage from '@Page/user/board/ReviewPage'
import AdminDashBoardPage from '@Page/admin/cmm/AdminDashBoardPage'
import { AdminMainImagePage } from '@Page/admin/cmm/AdminMainImagePage'
import AdminProductCategoryPage from '@Page/admin/product/AdminProductCategoryPage'
import { Make3DPage } from '@Page/admin/cmm/AdminMake3DPage/Make3DPage,'
import AdminProductListPage from '@Page/admin/product/AdminProcutListPage'

import Header from '@Component/layout/Header'
import { ProductSearch } from '@Component/domains/product/Search/ProductSearch'

import NoticePage from '@Page/user/board/NoticePage'
import SideBar from '@Component/layout/SideBar'
import UserFindPage from '@Page/user/account/UserFindPage'
import SetFontSize from '@Component/function/SetFontSize/SetFontSize'
import SetLocation from '@Component/function/SetLocation/SetLocation'
import { ScrollToTop } from '@Component/function/ScrollToTop/ScrollToTop'
import CheckAdmin from '@Component/function/CheckAdmin'
import AdminProductPage from '@Page/admin/product/AdminProductPage'
import ActionHandler from '@Component/function/ActionHandler'

import AdminNoticePage from '@Page/admin/board/NoticePage'
import AdminInquiryPage from '@Page/admin/board/InquiryPage'
import AdminReviewPage from '@Page/admin/board/ReviewPage'
import UserResetPage from '@Page/user/account/UserResetPage'
import UserUpdatePage from '@Page/user/account/UserUpdatePage'
import RoleBoardPage from '@Page/admin/account/RoleBoardPage'
import PaymentRedirectPage from '@Page/user/cmm/Redirect'
import { incrementVisitorApi } from '@Api/cmm'

const Router = () => {
    incrementVisitorApi()

    return (
        <BrowserRouter>
            <ModalProvider />
            <ProductSearch />
            <SetLocation />
            <SetFontSize />
            <ScrollToTop />
            <ActionHandler />
            <Routes>
                <Route
                    path="/admin/*"
                    element={
                        <React.Fragment>
                            <Header isAdmin={true} />
                            <CheckAdmin />
                        </React.Fragment>
                    }
                />
                <Route path="/*" element={<Header isAdmin={false} />} />
            </Routes>
            <main>
                <Routes>
                    {/* 사용자 페이지 */}
                    <Route path="/" element={<IndexPage />} />
                    <Route path="/shopinfo" element={<AboutPage />} />
                    <Route path="/privacy" element={<PrivacyPage />} />
                    <Route path="/agreement" element={<AgreementPage />} />

                    {/* 사용자 회원관리 */}
                    <Route path="/user/login" element={<UserLoginPage />} />
                    <Route path="/user/join" element={<UserJoinPage />} />
                    <Route path="/user/update" element={<UserUpdatePage />} />
                    <Route path="/user/find/id" element={<UserFindPage />} />
                    <Route path="/user/find/pwd" element={<UserResetPage />} />
                    {/*<Route path="/user/find" element={<FindAccount/>}/>*/}

                    {/* 마이페이지 */}
                    <Route path="/mypage/*" element={<MyPage />} />

                    {/*/!* 상품 페이지 *!/*/}
                    <Route path="/product/list" element={<ProductListPage />} />
                    <Route
                        path="/product/list/:primaryCategory"
                        element={<ProductListPage />}
                    />
                    <Route
                        path="/product/list/:primaryCategory/:secondaryCategory"
                        element={<ProductListPage />}
                    />
                    <Route
                        path="/product/detail/:productId"
                        element={<ProductDetail />}
                    />

                    <Route
                        path="/product/order/detail/:productId"
                        element={<ProductDetail orderProduct={true} />}
                    />

                    {/*/!* 구매 관련 페이지 *!/*/}
                    <Route path="/order/:orderPath" element={<OrderPage />} />

                    {/*/!* 리다이렉트페이지 *!/*/}
                    <Route
                        path="/order/redirect"
                        element={<PaymentRedirectPage />}
                    />

                    {/*각종 게시글 페이지 */}
                    <Route path="/board/notice" element={<NoticePage />} />
                    <Route path="/board/inquiry" element={<InquiryPage />} />
                    <Route path="/board/review" element={<ReviewPage />} />

                    {/* 관리자 페이지 */}
                    <Route path="/admin" element={<AdminDashBoardPage />} />
                    <Route path="/admin/login" element={<UserLoginPage />} />

                    <Route
                        path="/admin/main-image"
                        element={<AdminMainImagePage />}
                    />

                    {/* 관리자 상품 관리 */}
                    <Route
                        path="/admin/product/category"
                        element={<AdminProductCategoryPage />}
                    />

                    <Route path="/admin/make-3d" element={<Make3DPage />} />

                    <Route
                        path="/admin/product/list"
                        element={<AdminProductListPage />}
                    />
                    <Route
                        path="/admin/product/list/:primaryCategory"
                        element={<AdminProductListPage />}
                    />
                    <Route
                        path="/admin/product/list/:primaryCategory/:secondaryCategory"
                        element={<AdminProductListPage />}
                    />

                    <Route
                        path="/admin/product"
                        element={<AdminProductPage />}
                    />

                    <Route
                        path="/admin/product/detail/:productId"
                        element={<AdminProductPage />}
                    />

                    <Route
                        path="/admin/product/freeView/:productId"
                        element={<ProductDetail />}
                    />

                    <Route
                        path="/admin/product/:productNum"
                        element={<ProductDetail />}
                    />

                    {/*/!* 관리자 게시글/문의글 관리 *!/*/}
                    <Route
                        path="/admin/board/list/notice"
                        element={<AdminNoticePage />}
                    />
                    <Route
                        path="/admin/board/list/inquiry"
                        element={<AdminInquiryPage />}
                    />
                    <Route
                        path="/admin/board/list/review"
                        element={<AdminReviewPage />}
                    />
                    <Route
                        path="/admin/board/list/unprocessed"
                        element={<AdminUnprocessedPage />}
                    />

                    {/*/!* 주문 관리 페이지 *!/*/}
                    <Route path="/admin/order" element={<AdminOrderPage />} />

                    <Route path="/admin/swagger" element={<SwaggerViewer />} />

                    <Route path="/admin/role" element={<RoleBoardPage />} />
                    {/* 이외 페이지 */}
                    <Route path="/*" element={<NotFound />} />
                </Routes>
            </main>
            <SideBar />
            <Footer />
            <Loading />
        </BrowserRouter>
    )
}

export default Router

