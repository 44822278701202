import {createAuthTokenApi} from '@Api/cmm'
import {getUserInfo} from '@Api/user/account'
import {getModalInstance} from '@Component/common/Modal/ModalProvider'
import {UserDto} from '@Types/user'
import {formatTime} from '@Util/stringUtils'

export const kakaoLogin = (accountLink?: boolean) => {
    const redirectUri = new URL(process.env.KAKAO_REDIRECT_URL!) // URL 객체 생성
    redirectUri.searchParams.append(
        'accountLink',
        accountLink ? 'true' : 'false'
    ) // 파라미터 추가

    window.location.href =
        `https://kauth.kakao.com/oauth/authorize?response_type=code` +
        `&client_id=${process.env.KAKAO_CLIENT_ID}` +
        `&redirect_uri=${encodeURIComponent(redirectUri.toString())}` // URL 인코딩하여 추가
}

export const naverLogin = async (accountLink?: boolean) => {
    const redirectUri = new URL(process.env.NAVER_REDIRECT_URI!) // URL 객체 생성
    redirectUri.searchParams.append(
        'accountLink',
        accountLink ? 'true' : 'false'
    ) // 파라미터 추가
    const NAVER_AUTH_URL = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${process.env.NAVER_CLIENT_ID!}&state=${process.env.NAVER_STATE!}&redirect_uri=${redirectUri}`;
    window.location.href = NAVER_AUTH_URL;
}

export const checkUserInfo = async (
    setUserInfo?: React.Dispatch<
        React.SetStateAction<UserDto.Info | undefined>
    >,
    alert?: boolean,
    refresh?: boolean
) => {
    const modal = getModalInstance()
    const userInfo = await getUserInfo(refresh)

    if (!userInfo) {
        if (alert) {
            modal?.alert(
                () => {
                    window.location.href = '/user/login'
                },
                '알림',
                '잘못된 접근입니다.',
                '이용을 위해 로그인이 필요합니다.'
            )
        }
        return false
    }

    if (setUserInfo) {
        setUserInfo(userInfo)
    } else {
        return true
    }
}

export const preventLoggedInAccess = async () => {
    const modal = getModalInstance()
    const userInfo = await getUserInfo()

    // 이미 로그인된 상태일 경우
    if (userInfo) {
        modal?.alert(
            () => {
                window.location.href = '/'
            },
            '알림',
            '잘못된 접근입니다.',
            '로그아웃 후 이용해주세요.'
        )
        return false
    }

    return true
}

// 인증번호 요청 핸들러
// createToken은 토큰 생성상태 알려주는것 이미생성되어있으면 생성안되게
export const createAuthToken = async (
    dto: any,
    createToken: boolean,
    setCreateToken: React.Dispatch<React.SetStateAction<boolean>>
) => {
    const modal = getModalInstance()
    //process.env.ALIM_READY == 'true'
    if (!dto['loginId'] || !dto['email'] || !dto['phoneNumber']) {
        modal?.alert(() => {
        }, '알림', '인증 번호 발급을위해 모든 정보를 입력해주새요.');
        return
    }

    if (
        createToken &&
        (await createAuthTokenApi({
            loginId: dto.loginId,
            phoneNumber: dto.phoneNumber,
            email: dto.email
        }))
    ) {
        setCreateToken(false)
        const showResultElement = document.getElementById('auth-token-message')
        if (showResultElement) {
            let countDown = 180 // 3분 카운트다운
            showResultElement.style.color = 'blue'
            const timer = setInterval(() => {
                showResultElement.innerText = formatTime(countDown--)
                if (countDown < 0) {
                    clearInterval(timer)
                    showResultElement.innerText = ''
                    modal?.alert(
                        () => setCreateToken(true),
                        '알림',
                        '인증시간이 초과되었습니다.',
                        '번호를 다시 발급해주세요'
                    )
                }
            }, 1000)
        }
    } else {
        modal?.alert(() => {
        }, '알림', '인증번호는 3분마다 재발급 가능합니다.')
    }
}
