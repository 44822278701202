import { getModalInstance } from '@Component/common/Modal/ModalProvider'
import React, { useEffect, useState } from 'react'
import { deleteBoardApi, getBoardApi } from '@Api/user/board'
import { setWishicon } from '@Service/wish/WishService'

import { privacyName, stripHTMLTags } from '@Util/stringUtils'
import ImageViewList from '@Component/common/Image/ImageView/ImageViewList'
import { ProductListItem } from '@Component/domains/product/ProductList/ProductList'
import { InquiryEditor } from '@Component/domains/board/Edit/InquiryEditor'
import { getUserInfo } from '@Api/user/account'
import { BoardDto } from '@Types/board'
import { UserDto } from '@Types/user'
import MainFrame, { ButtonOption } from '@Component/layout/MainFrame'
import styles from '@Component/domains/board/View/index.module.scss'
import { formatDate } from '@Util/dateUtile'

export const RequestView = (props: { boardId?: string , orderState?: string}) => {
    const modal = getModalInstance()
    const [board, setBoard] = useState<BoardDto.Response>()
    const [userInfo, setUserInfo] = useState<UserDto.Info>()
    const fetchData = async () => {
        if (props.boardId) {
            const response = await getBoardApi(props.boardId)
            if (response) {
                setBoard(response)
                setWishicon()
            }
            setUserInfo(await getUserInfo())
        }
    }

    useEffect(() => {
        fetchData()
    }, [props.boardId])

    const actionButton: ButtonOption[] = [
        ...(board?.user?.userId == userInfo?.userId && props.orderState?.includes('요청')
            ? [
                {
                    name: '요청 수정',
                    onClick: () => {
                        modal?.popup(
                            <InquiryEditor boardId={props.boardId} />,
                            '800px',
                            () => {
                                fetchData()
                            }
                        )
                    },
                },
            ]
            : []),
        ...(board?.user?.userId == userInfo?.userId && props.orderState?.includes('요청')
            ? [
                {
                    name: '요청 취소',
                    onClick: () => {
                        modal?.confirm(
                            () => {
                                deleteBoardApi(props.boardId!)
                            },
                            '확인',
                            props.orderState +'을 취소하겠습니까?'
                        )
                    },
                },
            ]
            : []),
    ]

    return (
        <MainFrame
            className={styles.boardView}
            title={'요청 내역'}
            marginZero={true}
            action={actionButton}
        >
            <ProductListItem product={board?.product} />
            <label>
                요청 제목
                <div className={styles.info}>
                    <span>작성자 : {privacyName(board?.user?.userName!)}</span>
                    <span>
                        작성일 :{' '}
                        {formatDate(board?.saveDate, 'yyyy/MM/dd HH:mm')}
                    </span>
                </div>
            </label>
            <div className={styles.textarea}>{board?.title}</div>

            <label>요청 내용</label>
            <div
                dangerouslySetInnerHTML={{
                    __html: stripHTMLTags(board?.content || ''),
                }}
                className={styles.textarea + ' ' + styles.content}
            ></div>
            <ImageViewList
                parentClass="board-image-list"
                imageUrl={board?.imageUrl || []}
            />
            <label>
                답변
                {board?.answer && (
                    <div className={styles.info}>
                        <span>작성자 : BROSPO</span>
                        <span>
                            작성일 :{' '}
                            {formatDate(
                                board.answer.saveDate,
                                'yyyy/MM/dd HH:mm'
                            )}
                        </span>
                    </div>
                )}
            </label>
            <div
                dangerouslySetInnerHTML={{
                    __html: stripHTMLTags(
                        board?.answer?.content || '답변이 등록되지 않았습니다.'
                    ),
                }}
                className={styles.textarea + ' ' + styles.content}
            ></div>
        </MainFrame>
    )
}
