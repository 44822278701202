import {useEffect, useRef} from 'react'
import {useLocation} from 'react-router-dom'

export const ScrollToTop = () => {
    const location = useLocation()

    const refer = useRef<string>('/')
    useEffect(() => {

        if (location.pathname != '/') {
            window.scrollTo(0, 0)
        }

    }, [location.search, location.pathname])

    return null
}
