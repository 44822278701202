import MainFrame from '@Component/layout/MainFrame'
import { OrderDto } from '@Types/order'
import styles from '../index.module.scss'
import { OrderTableItem } from '../OrderItem'
export const OrderTableList = (props: { orderList: OrderDto.Response[] }) => {
    return (
        <MainFrame title={'주문 내역'} marginZero={true}>
            <table className={styles.orderTable}>
                <thead>
                    <tr>
                        <th colSpan={8}>주문명</th>
                    </tr>

                    <tr>
                        <th colSpan={2}>주문상태</th>
                        <th colSpan={2}>결제수단</th>
                        <th colSpan={2}>주문자명</th>
                        <th colSpan={2}>주문일자</th>
                    </tr>

                    <tr>
                        <th colSpan={3}>주문번호</th>
                        <th colSpan={3}>배송번호</th>
                        <th colSpan={2}>주문금액</th>
                    </tr>
                </thead>
                <tbody>
                    {props.orderList?.map((order, index) => (
                        <OrderTableItem order={order} />
                    ))}
                    {props.orderList?.length === 0 && (
                        <tr>
                            <td colSpan={8}>
                                <div className={'empty-view'}>
                                    해당하는 주문이 없습니다.
                                </div>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </MainFrame>
    )
}
