import {UserDto, UserRole} from "@Types/user";
import FormField, {FormFieldProps} from "@Component/layout/FormField";
import React, {useEffect, useState} from "react";
import MainFrame from "@Component/layout/MainFrame";
import Button from "@Component/common/Input/Button";
import styles from "@Css/form.module.scss"
import {ValidationOption} from "@Util/formUtils";
import {checkUserInfo} from "@Service/user/UserService";
import {changeUserRoleApi} from "@Api/admin/account";
import {getModalInstance} from "@Component/common/Modal/ModalProvider";

export const RoleEditor = (props: {
    userInfo: UserDto.Info
}) => {
    const modal = getModalInstance()
    const [userInfo, setUserInfo] = useState<UserDto.Info>()

    const [roleUser, setRoleUser] = useState<UserDto.Info>()
    const inquiryForm: FormFieldProps[] = [
        {
            label: '유저 권한 변경',
            placeholder: '유저권한 선택',
            options: [
                {value: 'USER', label: '일반유저'},
                {value: 'ADMIN', label: '관리자'},
                ...(userInfo?.role == UserRole.SYS ? [{value: 'ROOT_ADMIN', label: '루트 관리자'}] : [])
            ],
            inputType: 'select',
            name: 'role'
        },
    ];


    const roleValid: ValidationOption[] = [
        {
            name: 'role',
            baseMessage: '변경 권한을',
            disableSuccessMessage: true,
        },
    ];


    useEffect(() => {
        checkUserInfo(setUserInfo, true, true)
        setRoleUser(props.userInfo)
    }, [props.userInfo])

    if (!roleUser) {
        return null
    }

    return (
        <MainFrame className={styles.form} maxWidth={900} title={'유저 권한 수정'} marginZero={true}>
            <FormField formFieldList={inquiryForm} data={roleUser} setData={setRoleUser} validOption={roleValid}/>
            <Button
                name="권한변경"
                onClick={() => {
                    if (userInfo?.role == UserRole.ADMIN) {
                        modal?.alert(() => {
                            modal?.closeModal()
                        }, '알림', '변경 권한이 없습니다.')
                    } else {
                        changeUserRoleApi(roleUser)
                    }
                }}
            />
        </MainFrame>
    )
}
