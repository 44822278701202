import React from 'react'
import './About.css'

export const About = () => {
    return (
        <div className={'about-info'}>
            <table className="info-table">
                <colgroup>
                    <col width="25%" />
                    <col width="*" />
                </colgroup>
                <tbody>
                    <tr>
                        <th>상점명</th>
                        <td>{process.env.SITE_NAME}</td>
                    </tr>
                    <tr>
                        <th>대표</th>
                        <td>{process.env.REPRESENTATIVE}</td>
                    </tr>
                    <tr>
                        <th>대표전화</th>
                        <td>{process.env.PHONE}</td>
                    </tr>
                    <tr>
                        <th>주소</th>
                        <td>{process.env.ADDRESS}</td>
                    </tr>
                    <tr>
                        <th>사업자등록번호</th>
                        <td>{process.env.BUSINESS_NUMBER} </td>
                    </tr>
                    <tr>
                        <th>시스템 관리자</th>
                        <td>김태형</td>
                    </tr>
                    <tr>
                        <th>시스템 문의</th>
                        <td>edwsqa987@gmail.com</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}
