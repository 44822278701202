import {CmmDto} from "@Types/cmm/cmmDto";

export const createDefaultAnalytics = (analyticsDate: string):CmmDto.Analytics=>(
    {
        analyticsDate:analyticsDate,
        revenue:0,
        reviewCount:0,
        inquiryCount:0,
        orderCount:0,
        userCount:0,
        pageViewCount:0,
        visitorCount:0
    }
)