import { createAddressResponseDto } from '@Types/address'
import { OrderType } from '@Component/domains/order/OrderList/searchFeild'
import { subMonths } from 'date-fns'
import { OrderDto } from '@Types/order/orderDto'
import { BoardDto } from '@Types/board'

export const createOrderInsertDto = (): OrderDto.AdminInsert => ({
    orderDate: new Date(),
})
export const createOrderUpdateDto = (
    order?: OrderDto.Response
): OrderDto.UserUpdate => {
    const board = getLatestBoard(order)
    return {
        orderCode: order?.orderCode,
        orderState: order?.orderState,
        address: order ? undefined : createAddressResponseDto(),
        content: board?.content,
        title: board?.title,
        boardId: board?.boardId,
    }
}

export const createPaymentDto = (
    orderDto?: OrderDto.Response
): OrderDto.PaymentInfo => ({
    transactionType: '',
    txId: '',
    paymentId: orderDto?.orderCode,
    message: '',
    accountHolder: '',
    accountNumber: '',
})

export const createSearchDto = (orderType?: OrderType): OrderDto.Search => {
    const endDate = new Date()
    endDate.setHours(23, 59, 59, 999)

    return {
        orderCode: '',
        orderState: orderType || '',
        paymentType: '',
        deliveryNumber: '',
        userName: '',
        userPhone: '',
        startDate: subMonths(new Date(), 3),
        endDate: endDate,
    }
}

export const createAdminOrderUpdateDto = (
    order?: OrderDto.Response
): OrderDto.AdminUpdate => ({
    orderCode: order?.orderCode,
    orderState: order?.orderState,
    orderName: order?.orderName,
    orderDate: order?.orderDate,
    paymentType: order?.paymentType,
    paymentAmount: order?.paymentAmount?.toString(),
    accountCode: order?.accountCode,
    accountHolder: order?.accountHolder,
    accountNumber: order?.accountNumber,
    deliveryCode: order?.deliveryCode,
    deliveryNumber: order?.deliveryNumber,
    address: order?.address,
    recipientName: order?.recipientName,
    recipientContact: order?.recipientContact,
    deliveryRequest: order?.deliveryRequest,
    cancelAmt: ((order?.paymentAmount ?? 0) - (order?.cancelAmount ?? 0)).toLocaleString(),
})

function getLatestBoard(
    response?: OrderDto.Response
): BoardDto.Save | undefined {
    if (!response) {
        return undefined
    }
    if (!response.orderRequestList || response.orderRequestList.length === 0) {
        return undefined
    }

    const latestRequest = response.orderRequestList.reduce<
        OrderDto.Request | undefined
    >((latest, current) => {
        if (
            !latest ||
            (current.stepDate &&
                new Date(current.stepDate) > new Date(latest.stepDate!))
        ) {
            return current
        }
        return latest
    }, undefined)

    return latestRequest?.board
}
