import {Agreement} from '@Component/domains/cmm/Document/Agreement/Agreement'
import MainFrame from "@Component/layout/MainFrame";

const AgreementPage = () => {

    return (
        <MainFrame title={'이용약관'} maxWidth={1000} documentTitle={'이용약관'}>
            <Agreement />
        </MainFrame>
    )
}

export default AgreementPage
