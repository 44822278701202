import React, {SetStateAction, useState} from "react";


interface Props {
    data: any
    name: string
    setData?: React.Dispatch<SetStateAction<any>>
    size?: string
}

const ReviewRating: React.FC<Props> = ({data, name, setData, size}) => {

    const [tempRating, setTempRating] = useState<string>()
    const handleRatingClick = (index: number) => {
        if (setData) {
            const tempDto = data
            tempDto[name] = (index + 1).toString()
            setData(tempDto)
        }
    }

    const handleRatingHover = (index: number) => {
        if (setData) {
            setTempRating((index + 1).toString())
        }
    }

    const handleRatingOut = () => {
        if (setData) {
            setTempRating(undefined)
        }
    }

    return (
        <div>
            {[...Array(5)].map((_, index) => {
                const isFilled = index + 1 <= (tempRating || data?.[name] || 0);
                const iconClass = `icon-star${isFilled ? '' : '-empty'}`;
                const iconSize = size || 'var(--font-size-1)';
                const cursorStyle = setData ? 'pointer' : 'default';

                return (
                    <i
                        key={index}
                        style={{
                            fontSize: iconSize,
                            cursor: cursorStyle
                        }}
                        className={iconClass}
                        onClick={() => handleRatingClick(index)}
                        onMouseOver={() => handleRatingHover(index)}
                        onMouseOut={handleRatingOut}
                    ></i>
                );
            })}
        </div>
    );
}

export default ReviewRating