import React from 'react'
import { OrderList } from '@Component/domains/order/OrderList'
import MainFrame from '@Component/layout/MainFrame'

export const MypageOrders = () => {
    return (
        <MainFrame title={'주문조회'}>
            <OrderList />
        </MainFrame>
    )
}

export default MypageOrders
