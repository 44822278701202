// OrderRequestList.tsx
import React, {useState} from 'react'
import {OrderDto} from '@Types/order'
import RequestItem from '@Component/domains/order/OrderInfo/RequestItem'
import Button from '@Component/common/Input/Button'
import MainFrame from '@Component/layout/MainFrame'
import styles from '@Component/domains/order/OrderInfo/index.module.scss'
import {getModalInstance} from '@Component/common/Modal/ModalProvider'
import {RequestEditor} from '@Component/domains/board/Edit/RequestEditor'
import {excelDownload} from "@Service/order";

interface Props {
    orderDto?: OrderDto.Response

    isAdmin?: boolean
    setRefresh?: React.Dispatch<React.SetStateAction<boolean>>
}

const OrderRequestList: React.FC<Props> = ({
                                               orderDto,
                                               setRefresh,
                                               isAdmin,
                                           }) => {
    const modal = getModalInstance()
    const [moreLoad, setMoreLoad] = useState<boolean>(false)
    if (orderDto?.orderRequestList?.length == 0) {
        return <></>
    }
    return (
        <MainFrame
            title={
                <>
                    {orderDto?.orderState} <span>{orderDto?.orderCode}</span>
                </>
            }
            hideSiteName={true}
            className={styles.info}
            action={
                !isAdmin
                    ? [
                        {
                            name: '교환/환불 신청',
                            onClick: () => {
                                modal?.popup(
                                    <RequestEditor orderDto={orderDto}/>
                                    , '900px'
                                    , () => {
                                        if (setRefresh) setRefresh(true)
                                    })
                            },
                        },
                    ]
                    : [ {
                        name: '엑셀 다운로드',
                        onClick: () => {
                            excelDownload(orderDto)
                        },
                    },]
            }
        >
            <div className={styles.request}>
                {orderDto?.orderRequestList
                    ?.slice(0, moreLoad ? orderDto.orderRequestList.length : 5)
                    .map((item, index) => (
                        <RequestItem request={item} key={index}/>
                    ))}
            </div>
            {orderDto?.orderRequestList?.length! > 5 && (
                <Button
                    name={moreLoad ? '닫기' : '전체내역보기'}
                    onClick={() => setMoreLoad(!moreLoad)}
                />
            )}
        </MainFrame>
    )
}

export default OrderRequestList
