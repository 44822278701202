import { getModalInstance } from '@Component/common/Modal/ModalProvider'
import FormField, { FormFieldProps } from '@Component/layout/FormField'
import MainFrame, { ButtonOption } from '@Component/layout/MainFrame'
import styles from '@Css/form.module.scss'
import { createAdminOrderUpdateDto, OrderDto } from '@Types/order'
import { setNumber } from '@Util/formUtils'
import { update } from 'lodash'
import { useEffect, useState } from 'react'
import {cancelPayment} from "@Api/admin/order";

const CancelPaymentModal = (props: { orderDto: OrderDto.Response }) => {
    const modal = getModalInstance()
    const [remainingAmount, setRemainingAmount] = useState<number>(0)
    const [cancelDto, setCancelDto] = useState<OrderDto.AdminUpdate>(
        createAdminOrderUpdateDto(props.orderDto)
    )

    const updateRemainingAmount = () => {
        const paymentAmount = Number(cancelDto.paymentAmount) || 0
        const orderCancelAmount = props.orderDto?.cancelAmount || 0

        const updatedAmount = paymentAmount - orderCancelAmount
        setRemainingAmount(updatedAmount) // 상태 업데이트
    }

    const reviewForm: FormFieldProps[] = [
        {
            label: `취소가능금액 : ${remainingAmount.toLocaleString()}`,
            placeholder: 'CANCEL AMOUNT',
            inputType: 'text',
            name: 'cancelAmt',
            onChange: (event) => setNumber(event),
        },
    ]

    const actionButton: ButtonOption[] = [
        {
            name: '환불',
            onClick: async () => {
                if (!cancelDto.cancelAmt || cancelDto?.cancelAmt == '0') {
                    modal?.alert(() => {}, '알림', '환불금액을 입력해주세요.')
                    return
                }
                const cancelAmt =
                    Number(cancelDto.cancelAmt.replaceAll(',', '')) || 0

                if (remainingAmount - cancelAmt < 0) {
                    modal?.alert(
                        () => {},
                        '알림',
                        '환불가능 금액을 초과하였습니다.'
                    )
                    return
                }
                if(await cancelPayment(cancelDto)){
                    modal?.alert(()=>{modal?.closeModal()},'알림','환불되었습니다.');
                }
            },
        },
    ]

    useEffect(() => {
        updateRemainingAmount()
    }, [cancelDto, props.orderDto])
    return (
        <MainFrame
            className={styles.form}
            title={'주문 환불'}
            marginZero={true}
            action={actionButton}
        >
            <FormField
                formFieldList={reviewForm}
                data={cancelDto}
                setData={setCancelDto}
            />
        </MainFrame>
    )
}

export default CancelPaymentModal
