import React, { useEffect } from 'react'

import NoticeList from '@Component/domains/board/List/NoticeList'
import MainFrame from '@Component/layout/MainFrame'
import {TabBar} from "@Component/layout/TabBar";
import {boardPageTabOptions} from "@Page/admin/board/boardTabOption";

function AdminNoticePage() {
    return (
        <MainFrame  maxWidth={1000}>
            <TabBar options={boardPageTabOptions} />
        <MainFrame
            title={'공지사항'}
            documentTitle={'공지사항'}

        >

            <NoticeList isAdmin={true} />
        </MainFrame>
        </MainFrame>
    )
}

export default AdminNoticePage
