import { ValidationOption } from '@Util/formUtils'
import { checkDuplicateApi } from '@Api/user/account'

export const saveValid: ValidationOption[] = [
    {
        name: 'loginId',
        baseMessage: '아이디를',
        regex: /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]{6,15}$/, // 영문과 숫자가 혼합된 6자 이상 15자 이하
        regexMessage: '6자 이상, 15자 이하의 영숫자를 혼합하여 입력해주세요.',
        duplication: (value) => {
            return checkDuplicateApi('ID', value)
        },
    },
    {
        name: 'password',
        baseMessage: '비밀번호를',
        customValid: (data) => {
            return data.password == data.passwordReEnter
        },
        customValidMessage: '비밀번호 재입력값이 틀립니다.',
        regex: /^(?=.*[!@#$%^&*()_+\\\-={}\[\]:;"'<>?,./|\\])(?=.*[a-zA-Z])(?=.*[0-9]).{6,}$/,
        regexMessage: '6자 이상, 영숫자와 특수문자를 혼합하여 입력해주세요.',
    },
    {
        name: 'passwordReEnter',
        baseMessage: '비밀번호 재입력을',
        customValid: (data) => {
            return data.password == data.passwordReEnter
        },
        customValidMessage: '비밀번호 재입력값이 틀립니다.',
        customValidTarget: 'password',
        successMessage: '사용가능한 비밀번호입니다.',
    },
    {
        name: 'email',
        baseMessage: '이메일을',
        regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        duplication: (value) => {
            return checkDuplicateApi('EMAIL', value)
        },
    },
    {
        name: 'userName',
        baseMessage: '이름을',
    },
    {
        name: 'phoneNumber',
        baseMessage: '전화번호를',
        regex: /^[0-9]{3}-[0-9]{4}-[0-9]{4}$/, // 전화번호 형식 검사 정규식
        regexMessage: '유효한 전화번호 형식이 아닙니다. (예: 010-1234-5678)',
        duplication: (value) => {
            return checkDuplicateApi('PHONE', value)
        },
    },
    {
        name: 'agreement',
        baseMessage: '약관 동의를',
        nullAbleMessage: '서비스이용약관에 동의해주세요.',
    },
    {
        name: 'privacy',
        baseMessage: '개인정보 처리방침 동의를',
        nullAbleMessage: '개인정보처리방침에 동의해주세요.',
    },
    {
        name: 'privacy',
        baseMessage: '개인정보 처리방침 동의를',
        nullAbleMessage: '개인정보처리방침에 동의해주세요.',
    },
    {
        name: 'authToken',
        baseMessage: '인증번호를',
    },
]
