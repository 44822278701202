import { FormFieldProps } from 'components/layout/FormField'
import { setEmail, setNumber, setPhone } from '@Util/formUtils'
export const resetForm: FormFieldProps[] = [
    {
        label: '아이디',
        placeholder: 'ID',
        inputType: 'text',
        name: 'loginId',
    },
    {
        label: '이메일',
        placeholder: 'EMAIL',
        inputType: 'text',
        name: 'email',
        onChange: (event) => setEmail(event),
    },
    {
        label: '전화번호',
        placeholder: 'PHONE NUMBER',
        inputType: 'text',
        name: 'phoneNumber',
        onChange: (event) => setPhone(event),
    },
    {
        label: '비밀번호',
        placeholder: 'PASSWORD',
        inputType: 'password',
        name: 'password',
    },
    {
        label: '비밀번호 확인',
        placeholder: 'PASSWORD REENTER',
        inputType: 'password',
        name: 'passwordRe',
    },
]
