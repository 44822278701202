import React, {useEffect, useState} from "react";
import {getCategoryListApi} from "@Api/cmm";
import {CategoryList, findPrimary} from "@Types/category";

interface CategorySelectorProps {
    data: any;
    setData: React.Dispatch<React.SetStateAction<any>>;
}

const CategorySelector: React.FC<CategorySelectorProps> = ({data, setData}) => {
    const [categoryList, setCategoryList] = useState<CategoryList>({});
    const [primaryCategory, setPrimaryCategory] = useState<string>('');
    const [secondaryCategory, setSecondaryCategory] = useState<string>('');

    const fetchData = async () => {
        const categories = await getCategoryListApi();
        setCategoryList(categories);

        // Set initial primary category based on props.data
        const initialPrimary = findPrimary(categories, data.categoryId);
        setPrimaryCategory(initialPrimary || '');
    };

    useEffect(() => {
        fetchData();
    }, []);

    // Update categories when data.categoryId changes
    useEffect(() => {
        if(data.categoryId){
            const initialPrimary = findPrimary(categoryList, data.categoryId);
            setPrimaryCategory(initialPrimary || '');
            setSecondaryCategory(data.categoryId);
        }
    }, [data.categoryId]);

    const handlePrimaryCategoryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedPrimaryCategory = event.target.value;
        setPrimaryCategory(selectedPrimaryCategory);

        // Update the secondary category selection based on primary category
        if (selectedPrimaryCategory === '비공개') {
            handleSecondaryCategoryChange('1');
        } else {
            handleSecondaryCategoryChange('');
        }
    };

    const handleSecondaryCategoryChange = (categoryId: string) => {
        const temp = { ...data, categoryId };
        setData(temp);
        setSecondaryCategory(categoryId);
    };

    return (
        <>
            <h3>카테고리 선택</h3>
            <select
                value={primaryCategory}
                onChange={handlePrimaryCategoryChange}
            >
                <option value="">-1차 카테고리-</option>
                {Object.keys(categoryList).map((category, index) => (
                    <option key={index} value={category}>
                        {category}
                    </option>
                ))}
                <option value="비공개">비공개</option>
            </select>

            <select
                value={secondaryCategory}
                onChange={(e) => handleSecondaryCategoryChange(e.target.value)}
                disabled={primaryCategory === '비공개'}
            >
                {primaryCategory === '비공개' ? (
                    <option value="1">비공개</option>
                ) : (
                    <>
                        <option value="">-2차 카테고리-</option>
                        {categoryList[primaryCategory]?.map((category, index) => (
                            <option key={index} value={category.categoryId}>
                                {category.secondaryName}
                            </option>
                        ))}
                    </>
                )}
            </select>
        </>
    );
};

export default CategorySelector;
