// modelConfig.ts
import * as THREE from 'three'

export interface ModelConfig {
    url: string
    position: THREE.Vector3
}

export const modelConfigs: Record<string, ModelConfig> = {
    BasketBall: {
        url: 'https://raw.githubusercontent.com/SIROBAKO/cdnFiles/main/models/model.gltf',
        position: new THREE.Vector3(-45, 0, 100),
    },
    'T-Shirt': {
        url: 'https://raw.githubusercontent.com/SIROBAKO/cdnFiles/main/models/model2.gltf',
        position: new THREE.Vector3(-0.4, 0.2, 0.7),
    },
    Shirt: {
        url: 'https://raw.githubusercontent.com/SIROBAKO/cdnFiles/main/models/model3.gltf',
        position: new THREE.Vector3(-0.35, 0, 0.75),
    },
    HoodT: {
        url: 'https://raw.githubusercontent.com/SIROBAKO/cdnFiles/main/models/model4.gltf',
        position: new THREE.Vector3(0.85, 0.1, 0.3),
    },
}

export const canvasConfig = {
    camera: {
        fov: 70, // 카메라 시야각(Field of View)
        aspect: 1.2, // 종횡비(Aspect Ratio)
        near: 0.1, // near 클리핑 평면
        far: 10000, // far 클리핑 평면
    },
    renderer: {
        clearColor: 0xd6d6d6, // 렌더링 배경색
        shadowMapEnabled: true, // 섀도우 맵 활성화
    },
    lights: [
        {
            type: 'directional',
            color: 0xffffff, // 방향광 색상
            intensity: 2, // 방향광 강도
            position: new THREE.Vector3(10, 3, 100), // 방향광 위치
        },
        {
            type: 'directional',
            color: 0xffffff, // 방향광 색상
            intensity: 2, // 방향광 강도
            position: new THREE.Vector3(10, 3, -100), // 방향광 위치
        },
        {
            type: 'ambient',
            color: 0xffffff, // 주변광 색상
            intensity: 1, // 주변광 강도
        },
    ],
    texture: {
        roughness: 0.5, // 표면 거칠기
        metalness: 0.2, // 금속성
    },
    controls: {
        enableDamping: true, // 댐핑 활성화
        dampingFactor: 0.25, // 댐핑 팩터
        enableZoom: false, // 줌 비활성화
        enablePan: false, // 패닝 비활성화
        rotateSpeed: 1.0, // 회전 속도
    },
    resizeDelay: 100, // 리사이즈 후 재생성 딜레이(ms)
}
