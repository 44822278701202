import {useEffect, useState} from "react";
import {getWishListApi} from "@Api/user/wish";
import {ProductListItem} from "../ProductList/ProductList";
import {ProductDto} from "@Types/product";
import MainFrame from "@Component/layout/MainFrame";
import {setWishicon} from "@Service/wish/WishService";

const WishView = () => {
    const [productList, setProductList] = useState<ProductDto.Response[]>()

    const fetchData = async () => {
        setProductList((await getWishListApi()) || [])
        await setWishicon()
    }
    useEffect(() => {
        fetchData()
    }, [])

    return (
        <MainFrame title={'위시리스트'} maxWidth={1000} marginZero={true}>
            {productList?.map((product,index) => (
                 <ProductListItem key={index} product={product} />
            ))}

            {productList?.length == 0 && (
                <div className="empty-view">위시리스트가 비어있습니다.</div>
            )}
        </MainFrame>
    )
}

export default WishView
