import { ValidationOption } from '@Util/formUtils'
import { checkDuplicateApi } from '@Api/user/account'

export const resetValid: ValidationOption[] = [
    {
        name: 'loginId',
        baseMessage: '아이디를',
        disableSuccessMessage: true,
    },
    {
        name: 'password',
        baseMessage: '비밀번호를',
        disableSuccessMessage: true,
    },
    {
        name: 'passwordRe',
        baseMessage: '비밀번호 재입력을',
        customValid: (data) => {
            return data.password == data.passwordRe
        },
        customValidMessage: '비밀번호 재입력값이 틀립니다.',
        customValidTarget: 'password',
        disableSuccessMessage: true,
    },
    {
        name: 'email',
        baseMessage: '이메일을',
        disableSuccessMessage: true,
    },
    {
        name: 'authToken',
        baseMessage: '인증번호를',
        disableSuccessMessage: true,
    },
]
