import MainFrame from '@Component/layout/MainFrame';
import styles from '@Css/form.module.scss';

import {formList, pwdFormList} from './form';
import {pwdSaveValid, saveValid} from './valid';
import Button from '@Component/common/Input/Button';
import React, {useEffect, useState} from 'react';
import {createUserUpdateDto, UserDto} from '@Types/user';
import {isValid} from '@Util/formUtils';
import {getModalInstance} from '@Component/common/Modal/ModalProvider';
import FormField from '@Component/layout/FormField';

import {checkUserInfo} from '@Service/user/UserService';
import {userUpdateApi} from '@Api/user/account';
import {useNavigate} from "react-router-dom";

const UserJoinPage = () => {
    const modal = getModalInstance();
    const navigate = useNavigate();
    const [saveDto, setSaveDto] = useState<UserDto.Update>({});
    const [userInfo, setUserInfo] = useState<UserDto.Info>();
    const [changePwd, setChangePwd] = useState<boolean>();

    useEffect(() => {
        checkUserInfo(setUserInfo, true, true);
    }, []);

    useEffect(() => {
        if (!changePwd) {
            setSaveDto(createUserUpdateDto(userInfo));
        }
    }, [userInfo]);

    useEffect(() => {
        if (!changePwd) {
            setSaveDto({
                ...saveDto,
                password: undefined,
                passwordReEnter: undefined,
            });
        }
    }, [changePwd]);

    const handleUserUpdate = async () => {
        modal?.confirm(async () => {
            const isSaveDtoValid = await isValid(saveDto, saveValid, true);
            const isPasswordValid = changePwd ? await isValid(saveDto, pwdSaveValid, true) : true;

            if (isSaveDtoValid && isPasswordValid && await userUpdateApi(saveDto)) {
                modal?.alert(() => {
                    checkUserInfo(setUserInfo,false,true)
                    navigate('/mypage/account')
                }, '알림', '회원정보가 수정되었습니다.');
            }
        }, '확인', '회원정보를 수정하시겠습니까?');
    };

    return (
        <MainFrame
            className={styles.form}
            maxWidth={800}
            title="회원정보수정"
            documentTitle="회원정보수정"
        >
            {!userInfo?.kakaoId && (
                <React.Fragment>
                    {changePwd && (
                        <FormField
                            formFieldList={pwdFormList}
                            data={saveDto}
                            setData={setSaveDto}
                            validOption={pwdSaveValid}
                        />
                    )}

                    <Button
                        extraClass={styles.halfButton}
                        name={'비밀번호변경' + (changePwd ? '취소' : '')}
                        onClick={() => setChangePwd((prev) => !prev)}
                    />
                </React.Fragment>
            )}

            <FormField
                formFieldList={formList}
                data={saveDto}
                setData={setSaveDto}
                validOption={saveValid}
            />

            <Button
                extraClass={styles.halfButton}
                name="회원정보수정"
                onClick={handleUserUpdate}
            />
        </MainFrame>
    );
};

export default UserJoinPage;
