import React from 'react';
import styles from './index.module.scss';

interface ItemProps {
    content: React.ReactNode;
    colSpan: number;
    empty?: boolean;
    hidden?: boolean;
}

const Item: React.FC<ItemProps> = ({ content, colSpan, empty, hidden }) => {
    const classNames = `${empty ? styles.empty : ''} ${hidden ? styles.hidden : ''}`;

    return (
        <td colSpan={colSpan} className={classNames}>
            {content}
        </td>
    );
};

export default Item;
