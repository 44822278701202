import {FormFieldProps} from "components/layout/FormField";

export const formList:FormFieldProps[] = [
    {
        label: '아이디',
        placeholder: 'ID',
        inputType: 'text',
        name: 'loginId'
    },
    {
        label: '비밀번호',
        placeholder: 'PASSWORD',
        inputType: 'password',
        name: 'password'
    }
];