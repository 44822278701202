export const BoardCategory = {
    Notice: 0,
    Inquiry: 1,
    Review: 2,
    Cancel: 3,
    Exchange: 4,
    Return: 5,
}

export const getBoardTitle = (category?: number) => {
    switch (category) {
        case BoardCategory.Notice:
            return '공지제목'
        case BoardCategory.Inquiry:
            return '문의제목'
        case BoardCategory.Review:
            return '한줄평'
        case BoardCategory.Cancel:
            return '요청제목'
        case BoardCategory.Exchange:
            return '요청제목'
        case BoardCategory.Return:
            return '요청제목'
        default:
            return '알 수 없는 카테고리'
    }
}

export const getBoardType = (category?: number) => {
    switch (category) {
        case BoardCategory.Notice:
            return '공지사항'
        case BoardCategory.Inquiry:
            return '상품문의'
        case BoardCategory.Review:
            return '상품리뷰'
        case BoardCategory.Cancel:
            return '취소요청'
        case BoardCategory.Exchange:
            return '교환요청'
        case BoardCategory.Return:
            return '반품요청'
        default:
            return '알 수 없는 카테고리'
    }
}

export const getBoardDescriptName = (category?: number) => {
    switch (category) {
        case BoardCategory.Notice:
            return '구매후기'
        case BoardCategory.Inquiry:
            return '문의내용'
        case BoardCategory.Review:
            return '리뷰내용'
        case BoardCategory.Cancel:
            return '요청내용'
        case BoardCategory.Exchange:
            return '요청내용'
        case BoardCategory.Return:
            return '요청내용'
        default:
            return '알 수 없는 카테고리'
    }
}
