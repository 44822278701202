import {useEffect, useRef, useState} from 'react'
import {useLocation} from 'react-router-dom'

const SetFontSize: React.FC = () => {
    const location = useLocation()
    const [windowWidth, setWindowWidth] = useState<number | undefined>(
        window.innerWidth
    )
    const prevWindowWidth = useRef<number | undefined>(window.innerWidth)

    useEffect(() => {
        const handleResize = () => {
            const currentWidth = window.innerWidth
            const prevWidth = prevWindowWidth.current

            // 480px 경계를 넘을 때만 업데이트
            if (
                (prevWidth && prevWidth >= 480 && currentWidth < 480) ||
                (prevWidth && prevWidth < 480 && currentWidth >= 480)
            ) {
                setWindowWidth(currentWidth)
            }
            // 768px 경계를 넘을 때만 업데이트
            else if (
                (prevWidth && prevWidth >= 768 && currentWidth < 768) ||
                (prevWidth && prevWidth < 768 && currentWidth >= 768)
            ) {
                setWindowWidth(currentWidth)
            }

            prevWindowWidth.current = currentWidth
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    // CSS 변수 정의
    const fontSizes: { [key: string]: string } = {
        '--font-size-3': '3rem',
        '--font-size-2-9': '2.9rem',
        '--font-size-2-8': '2.8rem',
        '--font-size-2-7': '2.7rem',
        '--font-size-2-6': '2.6rem',
        '--font-size-2-5': '2.5rem',
        '--font-size-2-4': '2.4rem',
        '--font-size-2-3': '2.3rem',
        '--font-size-2-2': '2.2rem',
        '--font-size-2-1': '2.1rem',
        '--font-size-2': '2rem',
        '--font-size-1-9': '1.9rem',
        '--font-size-1-8': '1.8rem',
        '--font-size-1-7': '1.7rem',
        '--font-size-1-6': '1.6rem',
        '--font-size-1-5': '1.5rem',
        '--font-size-1-4': '1.4rem',
        '--font-size-1-3': '1.3rem',
        '--font-size-1-2': '1.2rem',
        '--font-size-1-1': '1.1rem',
        '--font-size-1': '1rem',
        '--font-size-0-9': '0.9rem',
        '--font-size-0-8': '0.8rem',
        '--font-size-0-7': '0.7rem',
        '--font-size-0-6': '0.6rem',
        '--font-size-0-5': '0.5rem',
    }

    // 창 너비에 따라 업데이트된 글꼴 크기 계산
    const updatedFontSizes: { [key: string]: string } = {}

    Object.keys(fontSizes).forEach((key) => {
        const fontSize = parseFloat(fontSizes[key])

        if (windowWidth && windowWidth < 480) {
            const newSize = `${fontSize * 0.8}rem`
            updatedFontSizes[key] = newSize
        } else if (windowWidth && windowWidth < 768) {
            const newSize = `${fontSize * 0.9}rem`
            updatedFontSizes[key] = newSize
        } else {
            updatedFontSizes[key] = fontSize + 'rem'
        }
    })

    // CSS 변수로 업데이트된 글꼴 크기 적용
    useEffect(() => {
        const root = document.documentElement
        Object.keys(updatedFontSizes).forEach((key) => {
            root.style.setProperty(key, updatedFontSizes[key])
        })
    }, [updatedFontSizes])

    return null // 렌더링할 내용이 없다면 null을 반환합니다.
}

export default SetFontSize
