import React, {ChangeEvent} from 'react'
import DateInput from '@Component/common/Input/DatePicker/DatePicker' // 올바르게 import
import {subMonths} from 'date-fns'
import styles from './index.module.scss'
import Button from "@Component/common/Input/Button";
import placeholder from "lodash/fp/placeholder";

export type SearchType = 'text' | 'date' | 'select'

export interface SearchField {
    type: SearchType
    name?: string
    placeholder?: string
    label?: React.ReactNode
    options?: { value: string; label: string }[]
}

export interface ActionField {
    label: string,
    isBlack?: boolean,
    onClick: () => void
}

interface SearchFieldComponentProps {
    searchFieldList: SearchField[][],
    actionFieldList: ActionField[]
    data: any // data의 타입을 명확히 정의합니다
    setData: React.Dispatch<React.SetStateAction<any>> // useState에서 사용하는 setState 함수의 타입
}

const SearchField = ({
                         searchFieldList,
                         data,
                         setData,
                         actionFieldList
                     }: SearchFieldComponentProps) => {
    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const {name, value} = event.target;
        setData({
            ...data,
            [name.replace('search', '')]: value
        });
    };

    return (
        <table className={styles.search}>
            <colgroup>
                <col width="19%"/>
                <col width="31%"/>
                <col width="19%"/>
                <col width="31%"/>
            </colgroup>
            <tbody>
            {searchFieldList.map((row, rowIndex) => (
                <tr key={rowIndex}>
                    {row.map((field, fieldIndex) => (
                        <React.Fragment key={fieldIndex}>
                            <th>
                                <p>{field.label}</p>
                            </th>

                            <td colSpan={row.length > 1 ? 1 : 3}>
                                {field.type === 'text' && (
                                    <input
                                        type="text"
                                        onChange={handleChange}
                                        defaultValue={field.name ? data[field.name] || '' : ''}
                                        name={'search' + field?.name} // name 추가
                                        id={'search-input-' + field?.name}
                                        placeholder={field?.placeholder}
                                    />
                                )}
                                {field.type === 'select' &&
                                    field.options && (
                                        <select onChange={handleChange}
                                                name={'search' + field.name} // name 추가
                                                id={'search-input-' + field.name}>
                                            {field.options.map((option) => (
                                                <option
                                                    key={option.value}
                                                    value={option.value}
                                                >
                                                    {option.label}
                                                </option>
                                            ))}
                                        </select>
                                    )}
                                {field.type === 'date' && (
                                    <div className={field.type == 'date' && styles.flex || ''}>
                                        <DateInput
                                            selectedDate={data?.startDate || subMonths(
                                                new Date(),
                                                3
                                            )} // 임시 값, 실제 값을 넣어야 함
                                            setSelectedDate={(date) => {
                                                setData({
                                                    ...data,
                                                    startDate: date
                                                })
                                            }}
                                            start={true}
                                        />
                                        <span>-</span>
                                        <DateInput
                                            selectedDate={data?.endDate || new Date()} // 임시 값, 실제 값을 넣어야 함
                                            setSelectedDate={(date) => {
                                                setData({
                                                    ...data,
                                                    endDate: date
                                                })
                                            }}
                                        />
                                    </div>
                                )}
                            </td>
                        </React.Fragment>
                    ))}
                </tr>
            ))}
            </tbody>
            <tfoot>
            <tr>
                <td colSpan={4}>
                    {actionFieldList.map((action, index) =>
                        <Button
                            key={index}
                            name={action.label}
                            onClick={action.onClick}
                            black={action.isBlack}/>
                    )}
                </td>
            </tr>
            </tfoot>
        </table>
    )
}

export default SearchField
