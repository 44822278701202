import { setEmail, setNumber, setPhone } from '@Util/formUtils'
import { FormFieldProps } from 'components/layout/FormField'

export const findForm: FormFieldProps[] = [
    {
        label: '전화번호',
        placeholder: 'PHONE NUMBER',
        inputType: 'text',
        name: 'phoneNumber',
        onChange: (event) => setPhone(event),
    },
    {
        label: '이메일',
        placeholder: 'EMAIL',
        inputType: 'text',
        name: 'email',
        onChange: (event) => setEmail(event),
    },
    {
        label: '이름',
        placeholder: 'NAME',
        inputType: 'text',
        name: 'userName',
    },
]
