import React, { useEffect, useState } from 'react'
import { Editor } from '@Component/common/Input/Editor/Editor'

import MainFrame from '@Component/layout/MainFrame'
import viewStyles from '@Component/domains/board/View/index.module.scss'
import formStyles from '@Css/form.module.scss'
import FormField, {
    FormFieldProps,
    InputType,
} from '@Component/layout/FormField'
import {
    BoardCategory,
    BoardDto,
    getBoardDescriptName,
    getBoardTitle,
    getBoardType,
} from '@Types/board'
import ReviewRating from '@Component/domains/board/ReviewRating'
import { getAdminBoardApi, saveAnswerApi } from '@Api/admin/board'
import { stripHTMLTags } from '@Util/stringUtils'
import { formatDate } from '@Util/dateUtile'
import { ProductListItem } from '@Component/domains/product/ProductList'
import ImageViewList from '@Component/common/Image/ImageView/ImageViewList'
import { AnswerDto, createAnswerDto } from '@Types/answer'
import Button from '@Component/common/Input/Button'
import { stateOptions } from '@Types/order'
import { getModalInstance } from '@Component/common/Modal/ModalProvider'

export const AnswerEditor = (props: {
    boardId: string
    currentState?: string
}) => {
    const modal = getModalInstance()
    const [answerDto, setAnswerDto] = useState<AnswerDto.Save>(
        createAnswerDto(props.boardId)
    )
    const [board, setBoard] = useState<BoardDto.Response>()
    const [defaultData, setDefaultData] = useState<string>()

    const isOrderRequestBoard = [
        BoardCategory.Cancel,
        BoardCategory.Exchange,
        BoardCategory.Return,
    ].includes(board?.category!)

    const answerForm: FormFieldProps[] = [
        ...(isOrderRequestBoard && props.currentState?.includes('요청')
            ? [
                  {
                      label: '변경 상태',
                      inputType: 'select' as InputType,
                      name: 'orderState',
                      options: stateOptions(
                          board?.category === BoardCategory.Cancel
                              ? 'Cancel'
                              : board?.category === BoardCategory.Exchange
                              ? 'Exchange'
                              : board?.category === BoardCategory.Return
                              ? 'Return'
                              : 'None'
                      ),
                      placeholder: '변경상태를 선택해주세요.',
                  },
              ]
            : []),
        {
            label: '답변',
            inputType: 'subTitle' as InputType,
            rightLabel: (
                <Button
                    name="저장"
                    onClick={() => {
                        if (
                            isOrderRequestBoard &&
                            !answerDto.orderState &&
                            props.currentState?.includes('요청')
                        ) {
                            modal?.alert(
                                () => {},
                                '알림',
                                '변경상태를 선택해주세요.'
                            )
                        } else {
                            saveAnswerApi(answerDto)
                        }
                    }}
                />
            ),
        },
    ]

    const fetchData = async () => {
        if (props.boardId) {
            const response = await getAdminBoardApi(props.boardId)
            if (response) {
                setBoard(response)
                setAnswerDto({
                    ...answerDto,
                    ...(response.answer || {}),
                })
                setDefaultData(response.answer?.content)
            }
        }
    }

    useEffect(() => {
        fetchData()
    }, [props.boardId])

    useEffect(() => {})
    return (
        <MainFrame
            className={viewStyles.boardView + ' ' + formStyles.form}
            title={getBoardType(board?.category)}
            marginZero={true}
        >
            <ProductListItem product={board?.product} />
            <label>
                {getBoardTitle(board?.category)}
                <div className={viewStyles.info}>
                    <span>작성자 : {board?.user?.userName}</span>
                    <span>
                        작성일 :{' '}
                        {formatDate(board?.saveDate, 'yyyy/MM/dd HH:mm')}
                    </span>
                </div>
            </label>
            <div className={viewStyles.textarea}>{board?.title}</div>

            <label>
                {getBoardDescriptName(board?.category)}
                {board?.category == BoardCategory.Review && (
                    <ReviewRating data={board} name={'reviewRating'} />
                )}
            </label>
            <div
                dangerouslySetInnerHTML={{
                    __html: stripHTMLTags(board?.content || ''),
                }}
                className={viewStyles.textarea + ' ' + viewStyles.content}
            ></div>
            <ImageViewList
                parentClass="board-image-list"
                imageUrl={board?.imageUrl || []}
            />
            <FormField
                formFieldList={answerForm}
                data={answerDto}
                setData={setAnswerDto}
            />
            <Editor
                name={'content'}
                data={answerDto}
                setData={setAnswerDto}
                userSet={true}
                height={300}
                defaultData={defaultData}
            />
        </MainFrame>
    )
}
