import {SearchField} from '@Component/layout/SearchField'

export const userSearch = (category ?: number): SearchField[][] => [
    [
        {
            type: 'text',
            name: 'userName',
            label: '이름',
            placeholder: '이름을 입력해주세요.',
        },
        {
            type: 'text',
            name: 'phoneNumber',
            label: '전화번호',
            placeholder: '전화번호를 입력해주세요.',
        },
    ],
    [
        {
            type: 'text',
            name: 'email',
            label: '이메일',
            placeholder: '이메일을 입력해주세요.',
        },
        {
            type: 'text',
            name: 'loginId',
            label: '아이디',
            placeholder: '아이디을 입력해주세요.',
        },
    ]
]
